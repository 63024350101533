import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ImgQuestion from '../assets/images/Iconos/icone-question300x300.png';

const DataCard = ({ id, name, title, summary, photoUrl, group, sections, onClick, isExpanded }) => {
  const handleCardClick = () => {
    if (!isExpanded) {
      onClick(id);
    }
  };

  return (
      <div
      layoutid={id} // Aquí lo cambiamos a minúsculas
      className={`custom-card ${group} ${isExpanded ? 'expanded' : ''}`}
      onClick={handleCardClick}
      style={{ cursor: 'pointer', width: isExpanded ? '100%' : 'auto', marginBottom: isExpanded ? '0' : '15px' }}
    >
      <img src={photoUrl} className="custom-card-img-top img-fluid" alt={`Imagen de ${name}`} />
      <div className="custom-card-body">
        <h5 className="custom-card-title">{title}</h5>
        <p className="custom-card-text">{summary}</p>
        {isExpanded && (
          <div className="custom-card-expanded-content">
            {sections.map((section, index) => (
              <div key={index}>
                <h6>{section.titleSection}</h6>
                {section.content.map((content, idx) => (
                  <p key={idx}>{content}</p>
                ))}
                {section.image && <img src={section.image} alt={`Imagen de ${section.titleSection}`} className="img-fluid img-section-full" />}
              </div>
            ))}
            <button className="boton-regreso" onClick={(e) => { e.stopPropagation(); onClick(null); }}>Cerrar</button>
          </div>
        )}
      </div>
    </div>
  );
};

DataCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  summary: PropTypes.string.isRequired,
  photoUrl: PropTypes.string.isRequired,
  group: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      titleSection: PropTypes.string.isRequired,
      content: PropTypes.arrayOf(PropTypes.string).isRequired,
      image: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};


const DataList = ({ dataToShow, groupToShow, data }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [expandedId, setExpandedId] = useState(null);

  useEffect(() => {
    let filtered = data.filter(item => item.module === "Combined");

    if (dataToShow) {
      filtered = filtered.filter(item => item.service.includes(dataToShow));
    }

    if (groupToShow) {
      filtered = filtered.filter(item => item.group === groupToShow);
    }

    setFilteredData(filtered);
  }, [dataToShow, groupToShow, data]);

  const handleCardClick = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  return (
    <Container fluid>
      <Row className='d-flex justify-content-center'>
        {filteredData.map((item) => (
          (expandedId === null || item.id === expandedId) && (
            <Col
              key={item.id}
              className={`custom-DataCard ${item.id === expandedId ? 'expand-Card' : ''} ${item.group}`}
              md={item.id === expandedId ? 12 : 3} // Cambia el tamaño del Col cuando está expandido
              style={{ display: expandedId === null || item.id === expandedId ? 'block' : 'none' }} // Oculta las tarjetas que no están expandidas
            >
              <DataCard
                id={item.id}
                name={item.name}
                title={item.title}
                summary={item.summary}
                photoUrl={item.photoUrl}
                sections={item.sections}
                isExpanded={item.id === expandedId}
                onClick={handleCardClick}
              />
            </Col>
          )
        ))}
      </Row>
    </Container>
  );
};

const MenuCardInfo = () => {
  const data = [
    {
      id: 1,
      module: "Combined",
      name: "Síntomas Frecuentes de la Consulta de Gastroenterología",
      title: "Síntomas Frecuentes de la Consulta de Gastroenterología",
      summary: "Descripción de los síntomas más comunes que llevan a los pacientes a consultar a un gastroenterólogo.",
      service: ["Consulta", "Diagnóstico"],
      photoUrl: ImgQuestion,
      group: "Consulta",
      sections: [
        {
          titleSection: "Introducción",
          content: [
            "Los síntomas gastrointestinales son una de las principales causas de consulta médica. Estos pueden variar en severidad y origen, afectando significativamente la calidad de vida del paciente."
          ]
        },
        {
          titleSection: "Síntomas Comunes",
          content: [
            "Dolor abdominal: Puede ser constante o intermitente, y su localización ayuda a orientar el diagnóstico.",
            "Reflujo gastroesofágico: Sensación de ardor en el pecho que sube hacia la garganta, generalmente después de comer.",
            "Diarrea: Evacuaciones líquidas frecuentes que pueden estar acompañadas de dolor abdominal.",
            "Estreñimiento: Dificultad para evacuar, con deposiciones duras y poco frecuentes.",
            "Náuseas y vómitos: Sensación de malestar en el estómago que puede llevar al vómito.",
            
          ],
          
        },
        {
          titleSection: "Hemorragia Digestiva",
          content: ["La hematemesis o vómito francamente hemático se relaciona con hemorragia masiva del tracto gastrointestinal alto(estomago, esófago y duodeno), el vómito de contenido fino, granular, oscuro, es el llamado vómito en borra de café, habla de una hemorragia activa o reciente, pero en grado menos severo La Melena o heces líquidas, negras y malolientes representan la degradación de la sangre que a atravesado el intestino delgado y grueso. La Hematoquecia o el pasaje de sangre vinosa o marrón y coágulos frescos por el recto es típica del colon."]
        },
        {
          titleSection: "Icteria",
          content: ["La icteria es una condición en la que el cuerpo no puede producir los nutrientes necesarios para el crecimiento y desarrollo de los organismos. La icteria se produce cuando el cuerpo no tiene suficientes nutrientes para producir los nutrientes que necesitan los organismos para crecer. La icteria puede ser causada por una variedad de factores, como la falta de nutrientes, la exposición a los agentes químicos, el estrés, la falta de sueño, la falta de actividad física, la falta de ejercicio, y la exposición a la luz. La icteria puede tener un impacto negativo en la salud, ya que puede causar problemas de salud como la diabetes, la hipertensión, la obesidad, y la enfermedad cardíaca."]
        },
        {
          titleSection: "Diarrea y Estreñimiento",
          content: ["Los cambios en los hábitos intestinales es un síntoma difícil de definir. El ritmo defecatorio normal depende de tantos factores, que cualquier indicio de cambio ser investigado a fondo. Es importante definir que un estreñimiento es cuando la evacuación es sólida, difícil de pasar y esporádica."]
        },
        {
          titleSection: "Dolor abdominal",
          content: ["Para determinar el dolor se divide al abdomen en 4 cuadrantes. El dolor correspondiente a Cuadrante Superior Derecho corresponde a Colecistitis o inflamación de Vesícula Biliar inflamaciones de Hígado. En Cuadrante Superior Izquierdo pueden deberse a Gastritis, Pancreatitis. En Cuadrante Inferior Derecho se debe sospechar Apendicitis y en el Cuadrante Inferior Izquierdo Diverticulitis por divertículos en el colon. Cada dolor tiene características especificas y una forma de presentación cronológica que se deben acompañar de estudios de imagen y gabinete. El aparato digestivo con el aparato urinario."]
        },
        {
          titleSection: "Nauseas y Vómito",
          content: ["La Nausea es una sensación displacentera, referida a la faringe y al abdomen superior y con la percepción de que el vomito es inminente El vómito es la expulsión oral forzada del contenido gástrico. Los síntomas agudos pueden ser indicativos de catástrofes abdominales como apendicitis aguda."]
        }
        // Añadir más secciones según sea necesario
      ],
    },
    
    {
      id: 2,
      module: "Combined",
      name: "ENDOSCOPIA DIGESTIVA ALTA",
      title: "ENDOSCOPIA DIGESTIVA ALTA O PANENDOSCOPIA",
      summary: "La Endoscopia Alta, Panendoscopia o Endoscopia Esófago-Gastro-Duodenal, es un método diagnóstico y terapéutico de enfermedades que involucran estas partes del tubo gástrico.",
      service: ["Cirugia", "Endoscopia Digestiva"],
      photoUrl: "https://unidad-gastroenterologia.com/images/endoscopia-icon.png",
      group: "Cirugia",
      sections: [
        {
          titleSection: "Introducción",
          content: [
            "La endoscopia digestiva alta permite la visualización directa del esófago, el estómago y el duodeno. Es un procedimiento valioso tanto para el diagnóstico como para el tratamiento de diversas condiciones gastrointestinales."
          ]
        },
        {
          titleSection: "Indicaciones",
          content: [
            "Evaluación de síntomas como dolor abdominal superior, náuseas, vómitos, dificultad para tragar y reflujo gastroesofágico.",
            "Diagnóstico de enfermedades como úlceras, gastritis, esofagitis, y cánceres gastrointestinales.",
            "Realización de biopsias para estudiar tejidos anormales.",
            "Tratamiento de ciertas condiciones, como el sangrado gastrointestinal, mediante técnicas como la coagulación o la escleroterapia."
          ]
        },
        {
          titleSection: "Preparación",
          content: [
            "El paciente debe ayunar al menos 6-8 horas antes del procedimiento.",
            "Es importante informar al médico sobre cualquier medicación que se esté tomando, especialmente anticoagulantes.",
            "El procedimiento generalmente se realiza bajo sedación para asegurar la comodidad del paciente."
          ]
        },
        {
          titleSection: "Procedimiento",
          content: [
            "Se introduce un endoscopio a través de la boca del paciente, permitiendo la visualización de la mucosa del esófago, estómago y duodeno.",
            "Se pueden tomar muestras de tejido (biopsias) para un análisis más detallado.",
            "El procedimiento dura entre 15 y 30 minutos."
          ],
          image: "https://unidad-gastroenterologia.com/images/endoscopia-digestiva.gif"
        },
        {
          titleSection: "Recuperación",
          content: [
            "El paciente debe permanecer en observación hasta que los efectos de la sedación desaparezcan.",
            "Es normal experimentar leves molestias en la garganta durante unos días.",
            "Se deben seguir las recomendaciones del médico respecto a la dieta y la actividad física."
          ]
        },
        // Añadir más secciones según sea necesario
      ],
    },
    {
      id: 3,
      module: "Combined",
      name: "Colonoscopy",
      title: "COLONOSCOPIA Y RECTOSIGMOIDOSCOPIA",
      summary: "Procedimiento diagnóstico para examinar el interior del colon y el recto.",
      service: ["Cirugia", "Diagnóstico"],
      photoUrl: "https://unidad-gastroenterologia.com/images/colonoscopia-icon.png",
      group: "Cirugia",
      sections: [
        {
          titleSection: "Introducción",
          content: [
            "La colonoscopia es un procedimiento que permite la visualización directa del interior del colon y el recto. Se utiliza tanto para el diagnóstico como para el tratamiento de enfermedades colorrectales."
          ]
        },
        {
          titleSection: "Indicaciones",
          content: [
            "Evaluación de síntomas como sangrado rectal, dolor abdominal, y cambios en el hábito intestinal.",
            "Diagnóstico y seguimiento de enfermedades inflamatorias intestinales como la colitis ulcerosa y la enfermedad de Crohn.",
            "Detección y eliminación de pólipos, que pueden ser precancerosos.",
            "Investigación de resultados anormales en estudios de imagen o pruebas de sangre."
          ]
        },
        {
          titleSection: "Preparación",
          content: [
            "El paciente debe seguir una dieta especial y tomar laxantes para limpiar el colon antes del procedimiento.",
            "Es importante informar al médico sobre cualquier medicación que se esté tomando, especialmente anticoagulantes.",
            "El procedimiento generalmente se realiza bajo sedación para asegurar la comodidad del paciente."
          ]
        },
        {
          titleSection: "Procedimiento",
          content: [
            "Se introduce un colonoscopio a través del recto, permitiendo la visualización del interior del colon.",
            "Se pueden tomar muestras de tejido (biopsias) para un análisis más detallado.",
            "El procedimiento dura entre 30 y 60 minutos."
          ],
          image: "https://unidad-gastroenterologia.com/images/colonoscopy.gif"
        },
        {
          titleSection: "Recuperación",
          content: [
            "El paciente debe permanecer en observación hasta que los efectos de la sedación desaparezcan.",
            "Es normal experimentar leves molestias abdominales y gases debido al aire introducido durante el procedimiento.",
            "Se deben seguir las recomendaciones del médico respecto a la dieta y la actividad física."
          ]
        },
        // Añadir más secciones según sea necesario
      ],
    },
    
    {
      id: 4,
      module: "Combined",
      name: "Manometría Esofágica",
      title: "MANOMETRÍA ESOFÁGICA CONVENCIONAL Y DE ALTA DEFINICIÓN",
      summary: "Procedimiento para medir la presión y el movimiento de los músculos del esófago.",
      service: ["Diagnóstico"],
      photoUrl: "https://unidad-gastroenterologia.com/images/manometria-esofagica-icon.png",
      group: "Diagnóstico",
      sections: [
        {
          titleSection: "Introducción",
          content: [
            "La manometría esofágica es una prueba que evalúa la función del esófago midiendo la presión y el movimiento de sus músculos. Es fundamental para el diagnóstico de trastornos esofágicos."
          ]
        },
        {
          titleSection: "Indicaciones",
          content: [
            "Evaluación de trastornos de la motilidad esofágica como la acalasia y el espasmo esofágico difuso.",
            "Investigación de síntomas como la disfagia (dificultad para tragar), el dolor torácico no cardíaco y el reflujo gastroesofágico.",
            "Evaluación preoperatoria antes de cirugías esofágicas."
          ]
        },
        {
          titleSection: "Preparación",
          content: [
            "El paciente debe ayunar al menos 6 horas antes del procedimiento.",
            "Es importante informar al médico sobre cualquier medicación que se esté tomando, especialmente aquellas que afectan la motilidad esofágica.",
            "El procedimiento no requiere sedación."
          ]
        },
        {
          titleSection: "Procedimiento",
          content: [
            "Se introduce una sonda fina a través de la nariz hasta el estómago. La sonda contiene múltiples sensores que miden la presión a lo largo del esófago.",
            "El paciente realizará varias degluciones de agua durante la prueba para evaluar la función esofágica.",
            "El procedimiento dura entre 30 y 45 minutos."
          ],
          image: "https://unidad-gastroenterologia.com/images/manometria.gif"
        },
        {
          titleSection: "Interpretación de Resultados",
          content: [
            "Los resultados de la manometría esofágica se utilizan para identificar anomalías en la motilidad esofágica. Pueden detectar condiciones como acalasia, espasmo esofágico, y otros trastornos de la motilidad.",
            "La manometría de alta definición proporciona un mapa detallado de la presión esofágica, permitiendo una evaluación más precisa de la función esofágica."
          ]
        },
        {
          titleSection: "Recuperación",
          content: [
            "La mayoría de los pacientes pueden reanudar sus actividades normales inmediatamente después del procedimiento.",
            "Es posible experimentar una leve molestia nasal o faríngea después de la prueba, que suele desaparecer rápidamente."
          ]
        },
        // Añadir más secciones según sea necesario
      ],
    }
    ,
    {
      id: 500,
      module: "Combined",
      name: "Phmetría Esofágica",
      title: "PHMETRÍA ESOFÁGICA CONVENCIONAL CON IMPEDANCIA DE ALTA RESOLUCIÓN",
      summary: "Procedimiento para medir el nivel de ácido en el esófago y evaluar el reflujo gastroesofágico.",
      service: ["Diagnóstico"],
      photoUrl: "https://unidad-gastroenterologia.com/images/phmetria-esofagica-icon.png",
      group: "Diagnóstico",
      sections: [
        {
          titleSection: "Introducción",
          content: [
            "La pHmetría esofágica con impedancia es una prueba que mide la cantidad de ácido que refluye desde el estómago al esófago. Es esencial para el diagnóstico del reflujo gastroesofágico (ERGE) y otras afecciones relacionadas."
          ]
        },
        {
          titleSection: "Indicaciones",
          content: [
            "Evaluación de síntomas de reflujo gastroesofágico, como acidez y regurgitación.",
            "Monitoreo de la efectividad del tratamiento para el reflujo gastroesofágico.",
            "Investigación de síntomas no cardíacos de dolor torácico y otros síntomas atípicos del reflujo."
          ]
        },
        {
          titleSection: "Preparación",
          content: [
            "El paciente debe ayunar al menos 6 horas antes del procedimiento.",
            "Se debe informar al médico sobre cualquier medicación que se esté tomando, especialmente inhibidores de la bomba de protones, antihistamínicos H2 y antiácidos.",
            "El procedimiento generalmente no requiere sedación."
          ]
        },
        {
          titleSection: "Procedimiento",
          content: [
            "Se introduce una sonda fina a través de la nariz hasta el estómago. La sonda mide el pH a lo largo del esófago durante un período de 24 horas.",
            "El paciente lleva un registrador portátil que registra los niveles de pH y otros datos a lo largo del día y la noche.",
            "El paciente debe llevar un diario de sus comidas, actividades y síntomas durante el período de monitoreo."
          ],
          image: "https://unidad-gastroenterologia.com/images/phmetria.gif"
        },
        {
          titleSection: "Interpretación de Resultados",
          content: [
            "Los resultados de la pHmetría esofágica se utilizan para determinar la frecuencia y duración del reflujo ácido. Esto ayuda a confirmar el diagnóstico de ERGE y evaluar la gravedad de la enfermedad.",
            "La impedancia esofágica mide tanto el reflujo ácido como el no ácido, proporcionando una imagen más completa del reflujo gastroesofágico."
          ]
        },
        {
          titleSection: "Recuperación",
          content: [
            "La mayoría de los pacientes pueden reanudar sus actividades normales inmediatamente después de retirar la sonda.",
            "Es posible experimentar una leve molestia nasal o faríngea después de la prueba, que suele desaparecer rápidamente."
          ]
        },
        // Añadir más secciones según sea necesario
      ],
    }
    ,
    {
      id: 5,
      module: "Combined",
      name: "Phmetría Esofágica",
      title: "Enfermedades Frecuentes de Gastroenterología",
      summary: "Procedimiento para medir el nivel de ácido en el esófago y evaluar el reflujo gastroesofágico.",
      service: ["Diagnóstico"],
      photoUrl: "https://unidad-gastroenterologia.com/images/phmetria-esofagica-icon.png",
      group: "Diagnóstico",
      sections: [
        {
          titleSection: "Introducción",
          content: [
            "La pHmetría esofágica con impedancia es una prueba que mide la cantidad de ácido que refluye desde el estómago al esófago. Es esencial para el diagnóstico del reflujo gastroesofágico (ERGE) y otras afecciones relacionadas."
          ]
        },
        {
          titleSection: "Indicaciones",
          content: [
            "Evaluación de síntomas de reflujo gastroesofágico, como acidez y regurgitación.",
            "Monitoreo de la efectividad del tratamiento para el reflujo gastroesofágico.",
            "Investigación de síntomas no cardíacos de dolor torácico y otros síntomas atípicos del reflujo."
          ]
        },
        {
          titleSection: "Preparación",
          content: [
            "El paciente debe ayunar al menos 6 horas antes del procedimiento.",
            "Se debe informar al médico sobre cualquier medicación que se esté tomando, especialmente inhibidores de la bomba de protones, antihistamínicos H2 y antiácidos.",
            "El procedimiento generalmente no requiere sedación."
          ]
        },
        {
          titleSection: "Procedimiento",
          content: [
            "Se introduce una sonda fina a través de la nariz hasta el estómago. La sonda mide el pH a lo largo del esófago durante un período de 24 horas.",
            "El paciente lleva un registrador portátil que registra los niveles de pH y otros datos a lo largo del día y la noche.",
            "El paciente debe llevar un diario de sus comidas, actividades y síntomas durante el período de monitoreo."
          ],
          image: "https://unidad-gastroenterologia.com/images/phmetria.gif"
        },
        {
          titleSection: "Interpretación de Resultados",
          content: [
            "Los resultados de la pHmetría esofágica se utilizan para determinar la frecuencia y duración del reflujo ácido. Esto ayuda a confirmar el diagnóstico de ERGE y evaluar la gravedad de la enfermedad.",
            "La impedancia esofágica mide tanto el reflujo ácido como el no ácido, proporcionando una imagen más completa del reflujo gastroesofágico."
          ]
        },
        {
          titleSection: "Recuperación",
          content: [
            "La mayoría de los pacientes pueden reanudar sus actividades normales inmediatamente después de retirar la sonda.",
            "Es posible experimentar una leve molestia nasal o faríngea después de la prueba, que suele desaparecer rápidamente."
          ]
        },
        // Añadir más secciones según sea necesario
      ],
    }
    ,
  ];

  return <DataList data={data} />;
};

export default MenuCardInfo;
