import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import ServiceCard from './extras/ServiceCard.jsx';
import ServicesData from '../assets/data/Services.json';
import '../assets/css/CardsMenu/ServiceCard.css';

const  ServiceList = ({ serviceToShow, groupToShow, moduleToShow }) => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    let filteredServices = ServicesData.filter(service =>
      Array.isArray(service.modules) && service.modules.includes(moduleToShow)
    );
  
    if (serviceToShow) {
      filteredServices = filteredServices.filter(service =>
        Array.isArray(service.service) && service.service.includes(serviceToShow)
      );
    }
  
    if (groupToShow) {
      filteredServices = filteredServices.filter(service =>
        service.group === groupToShow
      );
    }
  
    setServices(filteredServices);
  }, [serviceToShow, groupToShow, moduleToShow]);
  
  const handleClose = () => setSelectedService(null);
  const handleShow = (service) => setSelectedService(service);

  return (
    <Container>
      <Row className='d-flex justify-content-center'>
        {services.map((service) => (
          <Col md={6} lg={4} key={service.id} className={`ServiceCard d-flex justify-content-center ${service.group}`}>
            <ServiceCard
              id={service.id}
              name={service.name}
              title={service.title}
              service={service.service.join(', ')}
              photoUrl={service.photoUrl}
              className={service.service.join(' ')}
              group={service.group}
              link={service.link}
              onClick={() => handleShow(service)} // Expandir tarjeta al hacer clic
            />
          </Col>
        ))}
      </Row>

      {/* Modal para la información expandida del servicio */}
      {selectedService && (
        <Modal size="lg" show={true} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>{selectedService.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={selectedService.photoThumbnail} className="img-fluid mb-3 service-details-img-expand" alt={`Imagen de ${selectedService.name}`} />
            <p>Servicio: {selectedService.service.join(', ')}</p>
            {/* <h3>{selectedService.descriptionSercice}</h3> */}
            <p>{selectedService.descriptionSercice}</p>

            {Array.isArray(selectedService.modules) && 
            selectedService.modules.some(module => module === 'Gastroenterología' || module === 'Coloproctología' || module === 'Endoscopia Digestiva') &&
            selectedService.service.some(service => service === 'Colonoscopía' || service === 'Estudios') && (
              <>
                <h5>DIAGNÓSTICO ENDOSCÓPICO PRECISO</h5> 
                <p>
                  Para que su estudio sea óptimo, contamos con endoscopista y anestesiólogo profesionales, calificados y certificados.
                  Tenemos las condiciones ideales en nuestra sala de endoscopia. Nuestro equipo endoscópico de Alta Resolución es de última generación
                  con agrandamiento de imagen y cambio de luz específica, lo cual hace evidentes lesiones que no se observan con la imagen y luz convencional de xenón.
                </p>
                <p>
                  Contamos con equipo <strong>Olympus Evis Exera 180 con NBI</strong>.
                </p>
                <h5>¿DÓNDE Y CÓMO SE REALIZA UNA {selectedService.name}?</h5>
                <p>Todos los estudios se realizan en <span className='text-primary'>Medicci: Unidad de Gastroenterología y Endoscopia Digestiva</span>, en sala de Endoscopia y bajo sedación anestésica.</p>
                <h6 className='text-uppercase py-3'>Que costo tiene una {selectedService.name}?</h6>
                <p className='text-uppercase'>
                  {selectedService.priceVariations.conventional.name}:&nbsp;
                  <span className='text-card-price'>
                    ${selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                  </span>
                </p>
              </>
            )}


            {selectedService.modules.includes('Gastroenterología') && selectedService.service.includes('Manometria') && (
              <>
                <h5>Manometría Esofágica</h5> 
                <p> La manometría esofágica es un procedimiento diagnostico que evalúa la motilidad del esófago, midiendo la presión y la coordinación de las contracciones musculares en este organo. Es particularmente útil para diagnosticar trastornos como acalasía, reflujo gastroesofágico y otro problemas de deglución. Es realizada por un médico gástroenterologo con alta especialidad en fisiología digestiva.
                </p>
                <h5>¿DÓNDE Y CÓMO SE REALIZA UNA {selectedService.name}?</h5>
                <p>Todos los estudios se realizan en <span className='text-primary'>Medicci: Unidad de Gastroenterología y Endoscopia Digestiva</span>, en sala de Endoscopia y bajo sedación anestésica.</p>
                <h6 className='text-uppercase py-3'>Que costo tiene una {selectedService.name}?</h6>
                <p className='text-uppercase'>
                  {selectedService.priceVariations.conventional.name}:&nbsp;
                  <span className='text-card-price'>
                    {selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                  </span>
                </p>
                <p className='text-uppercase'>
                  {selectedService.priceVariations.highResolution.name}:&nbsp;
                  <span className='text-card-price'>
                    {selectedService.priceVariations.highResolution.price} MXN {selectedService.priceVariations.highResolution.NeedIva}
                  </span>
                </p>
              </>
            )}

            {selectedService.modules.includes('Gastroenterología') && selectedService.service.includes('Consulta') && (
              <>
                <h5>Consulta Especializada</h5> 
                <p>Nuestro equipo de Médicos Especialistas está comprometido a brindarle la mejor atención y
                  servicio a sus pacientes, garantizando así una atención médica de alta calidad y respaldada por lo ultimos avances en la Gastroenterología.
                </p>
                <h5>¿DÓNDE Y CÓMO SE REALIZA UNA {selectedService.name}?</h5>
                <ul className='unstyled'>
                  <li className='text-primary'>Medicci Insurgentes Sur: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Colonia Condesa: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Tlalpan Zona de Hospitales: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                </ul>
                <h6 className='text-uppercase py-3'>Que costo tiene una {selectedService.name}?</h6>
                <p className='text-uppercase'>
                  {selectedService.priceVariations.conventional.name}:&nbsp;
                  <span className='text-card-price'>
                    {selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                  </span>
                </p>
              </>
            )}
            {selectedService.modules.includes('Coloproctología') && selectedService.service.includes('Consulta') && (
              <>
                <h5 className='text-primary'>Consulta Especializada de Coloproctología</h5>
                  <p> Nuestro objetivo es brindar diagnosticos precisos y tratamientos personalizados a todas las enfermedades del colon, recto y ano. </p>
                  <p>La detección temprana es clave, ofrecemos colonoscopías asegurando un proceso seguro y comodo.</p>
                  <p>Tratamientos efectivos desde Hemorroides hasta Fistulas.</p>
                  <p>Enfoque integral en tratamiento de afecciones como Colitis Ulcerativa y Enfermedad de Chron.</p>
                <h5 className='text-primary'>¿DÓNDE Y CÓMO SE REALIZA UNA {selectedService.name}?</h5>
                <ul className='unstyled'>
                  <li className='text-primary'>Medicci Insurgentes Sur: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Colonia Condesa: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Tlalpan Zona de Hospitales: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                </ul>
                <h6 className='text-uppercase py-3'>¿Qué costo tiene una {selectedService.name}?</h6>
                <p className='text-uppercase'>
                  {selectedService.priceVariations.conventional.name}:&nbsp;
                  <span className='text-card-price'>
                    {selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                  </span>
                </p>
              </>
            )}
            {selectedService.modules.includes('Coloproctología') && selectedService.name.includes('COLONOSCOPIA Y RECTOSIGMOIDOSCOPIA') && (
              <>
              <h5>DIAGNÓSTICO ENDOSCÓPICO PRECISO</h5> 
              <p>
                Para que su estudio sea óptimo, contamos con endoscopista y anestesiólogo profesionales, calificados y certificados.
                Tenemos las condiciones ideales en nuestra sala de endoscopia. Nuestro equipo endoscópico de Alta Resolución es de última generación
                con agrandamiento de imagen y cambio de luz específica, lo cual hace evidentes lesiones que no se observan con la imagen y luz convencional de xenón.
              </p>
              <p>
                Contamos con equipo <strong>Olympus Evis Exera 180 con NBI</strong>.
              </p>
              <h5>¿DÓNDE Y CÓMO SE REALIZA UNA {selectedService.name}?</h5>
              <p>Todos los estudios se realizan en <span className='text-primary'>Medicci: Unidad de Gastroenterología y Endoscopia Digestiva</span>, en sala de Endoscopia y bajo sedación anestésica.</p>
              <h6 className='text-uppercase py-3'>Que costo tiene una Colonoscopía o Rectosigmoidoscopia?</h6>
              <p className='text-uppercase'>
                {selectedService.priceVariations.conventional.name}:&nbsp;
                <span className='text-card-price'>
                  ${selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                </span>
              </p>
            </>
            )}
            {selectedService.modules.includes('Coloproctología') && selectedService.name.includes('CLÍNICA DE VPH ANAL') && (
              <>
              <h4 className='text-primary text-uppercase'>{selectedService.typeService} {selectedService.name}</h4>
              <p><span className='text-primary'>¿Te preocupa el VPH?</span> Sabemos que el Virus del Papiloma Humano puede generar incertidumbre y ansiedad. ¡Pero no estás solo! En nuestra Clínica de VPH Anal, te ofrecemos un espacio seguro y profesional, donde tu salud y bienestar son nuestra prioridad.</p>
              <p>El VPH anal, o virus del papiloma humano anal, es una infección causada por ciertos tipos de virus del papiloma humano que pueden infectar la región anal. El VPH es un grupo de más de 200 virus relacionados, de los cuales algunos se consideran de alto riesgo para la salud porque pueden conducir al desarrollo de cáncer, incluyendo el cáncer anal.</p>
              <p>Aquí hay algunos puntos importantes sobre el VPH anal:</p>
              <ul>
                <li>Transmisión: Se transmite principalmente a través del contacto sexual, incluyendo relaciones sexuales anales y orales. También puede contaminar áreas cercanas a través del contacto piel con piel.</li>
                <li>Síntomas: Muchas personas infectadas por VPH no presentan síntomas. Sin embargo, algunos pueden desarrollar verrugas anales o cambios en las células que pueden ser detectados a través de pruebas médicas.</li>
                <li>Riesgo de cáncer: Algunos tipos de VPH, especialmente los tipos 16 y 18, están asociados con un mayor riesgo de cáncer anal. Este riesgo es más elevado en personas con sistemas inmunitarios comprometidos, como en el caso de las personas que viven con VIH.</li>
              </ul>
              <h5>Deteccion y tratamiento</h5>
              <p>Existen pruebas que pueden detectar la presencia del VPH y los cambios celulares asociados. En caso de que se detecten lesiones precoces, se pueden tratar para prevenir el desarrollo de cáncer.</p>
              <p>Es importante realizar chequeos regulares con un profesional de la salud si se está en un grupo de riesgo o si hay preocupaciones sobre la infección por VPH.</p>
              <p><span className='text-primary'>Expertos a tu Servicio</span> Nuestro equipo de coloproctologia está altamente capacitado en la prevención, diagnóstico y tratamiento del VPH anal. Te brindamos atención personalizada y confidencial, asegurando que te sientas cómodo en cada paso del proceso.</p>
              <p>Agenda tu Cita Hoy: No dejes que el miedo te detenga. Da el primer paso hacia una vida más saludable.</p>
              <p className='text-primary'>¡Confidencialidad y cuidado están garantizados!</p>
              <p className='text-uppercase'>
                {selectedService.priceVariations.conventional.name}:&nbsp;
                <span className='text-card-price'>
                  ${selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                </span>
              </p>
            </>
            )}
            {selectedService.modules.includes('Coloproctología') && selectedService.name.includes('ULTRASONIDO ENDOANAL 360') && (
              <>
              <h4 className='text-primary text-uppercase'>{selectedService.typeService} {selectedService.name}</h4>
              <p><span className='text-primary'>¿Buscas precisión, comodidad y tranquilidad en lo que respecta a tu salud anal??</span><br></br> El ultrasonido endoanal es una técnica de imagen que se utiliza para examinar el recto y el ano. Consiste en la inserción de una sonda de ultrasonido en el canal anal para obtener imágenes detalladas de la anatomía de esta área. Este procedimiento es especialmente útil para evaluar condiciones como:</p>
              <ul>
                <li><span className='text-primary'>Fístulas anales:</span> Conexiones anormales entre el interior del recto o el ano y la piel circundante.</li>
                <li><span className='text-primary'>Abscesos:</span> Acumulaciones de pus en los tejidos anales.</li>
                <li><span className='text-primary'>Lesiones tumorales:</span> Para detectar y evaluar crecimientos anormales.</li>
                <li><span className='text-primary'>Incontinencia anal:</span> Para evaluar el estado de los músculos y estructuras que controlan la continencia.</li>
              </ul>
              <p>El ultrasonido endoanal es un procedimiento relativamente simple y generalmente se realiza de manera ambulatoria. No requiere anestesia general y tiene pocos efectos secundarios. Las imágenes obtenidas ayudan a los médicos a planificar tratamientos quirúrgicos o terapéuticos más adecuados.</p>
              <p><span className='text-primary'>No Invasivo y Sin Dolor.</span> Experimenta un procedimiento de última generación que prioriza tu comodidad. La técnica de Ultrasonido Endoanal es no invasiva y mínimamente incómoda, diseñada especialmente para tu bienestar.</p>
              <p className='text-primary'>Contáctanos ahora. para obtener más información y programar tu consulta. Porque tu salud merece la mejor atención disponible.</p>
              <p className='text-uppercase'>
                {selectedService.priceVariations.conventional.name}:&nbsp;
                <span className='text-card-price'>
                  ${selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                </span>
              </p>
            </>
            )}
            {selectedService.modules.includes('Coloproctología') && selectedService.name.includes('MANOMETRÍA ENDOANAL de alta resolución') && (
              <>
              <h4 className='text-primary text-uppercase'>{selectedService.typeService} {selectedService.name}</h4>
              <p>La Manometria Endoanal es una  herramienta de diagnóstico de vanguardia diseñada para evaluar la función del esfínter anal y la sensación rectal, ¡incluso antes de que llegues a la clínica!</p>
              <p>La manometría endoanal evalúa la función del esfínter anal y la sensibilidad de la región anal. Este procedimiento se utiliza comúnmente para investigar trastornos anales, como la incontinencia fecal, el estreñimiento crónico y otros problemas relacionados con la función anal.</p>
              <p className='h5 text-primary'>Objetivos de la Manometría Endoanal</p>
              <ul>
                <li><span className='text-primary'>Determinar la Sensibilidad Anal:</span> Evalúa la capacidad del paciente para percibir estímulos (tacto y presión) en la región anal.</li>
                <li><span className='text-primary'>Observar la Función de Relajación:</span> En ciertas maniobras, es importante observar cómo el esfínter anal se relaja, especialmente durante el intento de evacuación.</li>
              </ul>
              <p className='h5 text-primary'>Cómo se realiza la Manometria Endoanal.</p>
              <ul>
                <li><span className='text-primary'>Preparación:</span> Se puede solicitar al paciente que siga algunas indicaciones, como no comer durante ciertas horas antes del examen.</li>
                <li><span className='text-primary'>Ejecución:</span> Se inserta un catéter flexible en la región anal, y se utilizan sensores para medir las presiones. El examen puede incluir la realización de maniobras como contracciones voluntarias e intentos de evacuación.</li>
                <li><span className='text-primary'>Duración:</span> Generalmente, el examen dura entre 15 y 30 minutos.</li>
              </ul>
              <p className='h5 text-primary'>Interpretación de los Resultados</p>
              <p>Los resultados son analizados por un médico especialista, quien puede determinar si hay alguna anormalidad en la función del esfínter, la sensibilidad o otras condiciones que puedan contribuir a los síntomas del paciente.</p>
              <p className='h5 text-primary'>Conclusión.</p>
              <p>La manometría endoanal es una herramienta importante para el diagnóstico y tratamiento de trastornos anales. Si está considerando este examen, es esencial discutir todas sus preocupaciones y las implicaciones de los resultados con un profesional de salud calificado.</p>
              <p className='text-uppercase'>
                {selectedService.priceVariations.conventional.name}:&nbsp;
                <span className='text-card-price'>
                  ${selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                </span>
              </p>
            </>
            )}
            {selectedService.modules.includes('Coloproctología') && selectedService.name.includes('BIOFEEDBACK O ELECTROESTIMULACIÓN ANAL') && (
              <>
              <h4 className='text-primary text-uppercase'>{selectedService.typeService} {selectedService.name}</h4>
              <p>El biofeedback es una técnica que implica el uso de dispositivos de monitoreo electrónico para transmitir información sobre los procesos fisiológicos del cuerpo en este caso de la función de los músculos del complejo esfintérico anal.</p>
              <p>El objetivo es mejorar la salud aprendiendo a controlar la funciones corporales de contracción y relajación del los esfínteres anales.</p>
              <p className='h5 text-primary'>Propósito del Biofeedback.</p>
              <ul>
                <li>Ayudar a las personas a tomar conciencia y controlar la continencia y el mecanismo de defecación.</li>
              </ul>
              <p className='h5 text-primary'>Cómo Funciona:</p>
              <ul>
                <li>Se colocan sensores en el ano para medir señales fisiológicas de construcción y relajación.</li>
                <li>Estas señales se muestran en un monitor en tiempo real, lo que permite a las personas ver las respuestas de su cuerpo.</li>
                <li>Se utiliza retroalimentación para enseñar al participante cómo modificar sus respuestas físicas, promoviendo la relajación y el control.</li>
              </ul>
              <p className='h5 text-primary'>Aplicaciones.</p>
              <ul>
                <li>Mejorar la continencia fecal.</li>
                <li>Rehabilitación de lesiones.</li>
                <li>Mejora la relajación y el mecanismo de defecación.</li>
                <li>Tratamiento de condiciones médicas específicas como.
                  <ul className='fw-semibold'>
                    <li>Incontinencia fecal</li>
                    <li>Disfunciones del piso pélvico</li>
                    <li>Anismus</li>
                    <li>Dolor anal crónico</li>
                    <li>Proctalgia fugax</li>
                  </ul>
                </li>
              </ul>
              <p className='h5 text-primary'>Consideraciones:</p>
              <ul className='unstyled'>
                <li>El biofeedback debe usarse como un enfoque complementario a los tratamientos médicos tradicionales.</li>
                <li>Las personas pueden necesitar entrenamiento de un profesional certificado en biofeedback para entender e interpretar las señales de manera efectiva.</li>
              </ul>
              <p className='text-uppercase'>
                {selectedService.priceVariations.conventional.name}:&nbsp;
                <span className='text-card-price'>
                  ${selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva} por Sesión
                </span>
              </p>
            </>
            )}

            {selectedService.modules.includes('Gastroenterología') && selectedService.service.includes('Phmetria') && (
              <>
                <h5>{selectedService.name}</h5> 
                <p>La pHMetría esofágica es un estudio que mide el pH del contenido esofágico para evaluar la presencia y la frecuencia del reflujo ácido. Este procedimiento es útil para diagnosticar transtornos como el reflujo gastroesofágico. Para llevar a cabo la pHMería se introduce un sensor a traves de la nariz y se coloca en el esofágo, donde permanece durante 24 horas para registrar los niveles de acidez. Este procedimiento lo realiza un Gástroenterologo con alta especialidad en fisiología digestiva.
                </p>
                <h5>¿DÓNDE Y CÓMO SE REALIZA UNA {selectedService.name}?</h5>
                <ul className='unstyled'>
                  <li className='text-primary'>Medicci Insurgentes Sur: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Colonia Condesa: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Tlalpan Zona de Hospitales: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                </ul>
                <h6 className='text-uppercase py-3'>Que costo tiene una {selectedService.name}?</h6>
                <p className='text-uppercase'>
                  {selectedService.priceVariations.conventional.name}:&nbsp;
                  <span className='text-card-price'>
                    {selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                  </span>
                </p>

                <p className='text-uppercase'>
                  {selectedService.priceVariations.highImpedance.name}:&nbsp;
                  <span className='text-card-price'>
                    {selectedService.priceVariations.highImpedance.price} MXN {selectedService.priceVariations.highImpedance.NeedIva}
                  </span>
                </p>
              </>
            )}
            {selectedService.modules.includes('CheckUp')&& (
              <>
                <h5 className='text-uppercase text-primary'>¿DÓNDE SE REALIZA UN Check Up?</h5>
                <ul className='unstyled'>
                  <li className='text-primary'>Medicci Insurgentes Sur: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Colonia Condesa: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Tlalpan Zona de Hospitales: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                </ul>
                {/* <h6 className='text-uppercase'>{selectedService.name}</h6> */}
                <p className='text-uppercase'>
                  {selectedService.priceVariations.conventional.name}:&nbsp;
                  <span className='text-card-price'>
                    {selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                  </span>
                </p>
              </>
            )}
            {selectedService.modules.includes('Ultrasonido') && (
              <>
                {/* Mostrar requisitos solo si están definidos */}
                {selectedService.requirements && (
                  <>
                    <h5>Preparación</h5>
                    <p>{selectedService.requirements}</p>
                  </>
                )}

                {/* Mostrar descripción solo si está definida */}
                {selectedService.description && (
                  <>
                    <h5>Descripción</h5>
                    <p>{selectedService.description}</p>
                  </>
                )}

                <h5 className='text-uppercase text-primary'>¿DÓNDE SE REALIZA UN Ultrasonido?</h5>
                <ul className='unstyled'>
                  <li className='text-primary'>Medicci Insurgentes Sur: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Colonia Condesa: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Tlalpan Zona de Hospitales: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                </ul>

                <h5 className='card-title'>¿Qué precio tiene?</h5>
                <p className='text-uppercase'>
                  {selectedService.priceVariations.conventional.name}:&nbsp;
                  <span className='text-card-price'>
                    {selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                  </span>
                </p>
              </>
            )}

            {selectedService.modules.includes('Gastroenterología') && selectedService.service.includes('PruebaAliento') && (
              <>
                <p>La Muestra de Aliento se recoge antes y despues de tomar una cápsula que mide la cantidad de Dioxido de Cpárbono en la muestra, lo que indica la presencia de la bácteria.</p>
                <h6>Ventajas</h6>
                <ul>
                  <li>Rápida y no invasiva</li>
                  <li>Menos incomoda que una endoscopía</li>
                  <li>Resultados dispoonibles en pocas horas</li>
                </ul>
                <h5>¿DÓNDE Y CÓMO SE REALIZA UNA {selectedService.name}?</h5>
                <ul className='unstyled'>
                  <li className='text-primary'>Medicci Insurgentes Sur: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Colonia Condesa: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                  <li className='text-primary'>Medicci Tlalpan Zona de Hospitales: Unidad de Gastroenterología y Endoscopia Digestiva.</li>
                </ul>
                <h6 className='text-uppercase'>Que costo tiene una {selectedService.name}?</h6>
                <p className='text-uppercase'>
                  {selectedService.priceVariations.conventional.name}:&nbsp;
                  <span className='text-card-price'>
                    {selectedService.priceVariations.conventional.price} MXN {selectedService.priceVariations.conventional.NeedIva}
                  </span>
                </p>
              </>
            )}

            <p className='h5 text-primary'>{selectedService.promotion}</p>
            
            {selectedService.included && (
              <>
                <h6>Incluye:</h6>
                <ul>
                  {selectedService.included.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
              </>
            )}

            {selectedService.excluded && (
              <>
                <h6>No incluye:</h6>
                <ul>
                  {selectedService.excluded.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary'
            href={`https://api.whatsapp.com/send?phone=5215534816054&text=Hola!%2C%20Me%20interesa%20${encodeURIComponent(selectedService.service)}%20de%20${encodeURIComponent(selectedService.name)}%2C%20podrias%20darme%20mas%20informes%3F`}
            >Más Informacíon</Button>
            <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default ServiceList;

