import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/UnidadesPages/styles.css';
import ServiceList from '../components/ServiceList.jsx';
import MenuCardInfo from '../components/MenuCardInfo.jsx';
import DoctorList from '../components/DoctorList';

const GastroenterologiaPage = ({ pageData }) => {
  const [selectedService, setSelectedService] = useState(null);

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>
      <section className="unidades-section">
        <Container fluid>
                <h1 className='text-uppercase'>CLÍNICA DE {pageData.title}</h1>
                <hr />
                <Row className='p-lg-5 d-flex align-items-center'>
                    <Col className='p-lg-5' md={6}>
                    <div className="info-item d-flex align-items-center">
                        <p>Especialidad Médica dedicada al tratamiento de enfermedades del tubo digestivo (esófago, estómago, intestino delgado) y glándulas asociadas (hígado, páncreas) ya sea con tratamiento médico, quirúrgico convencional, cirugía laparoscópico o terapéutica endoscópica.</p>
                    </div>
                    </Col>
                    <Col className='p-5 align-items-center' md={6}>
                    <div className="info-item d-flex justify-content-around">
                        <div>
                        <img className='img-fluid rounded-4' width={500} src="https://unidad-gastroenterologia.com/images/unidades/gastroenterologia.webp" alt="Imagen de unidad de gastroenterologia" />
                        </div>
                    </div>
                    </Col>
                    </Row>
                <hr />
        </Container>
        <Container fluid>
        <div className='doctor-section-content p-4'>
                <h2>Servicios que ofrecemos en la <br></br>Unidad de {pageData.title}:</h2>
                <p className='p-2 p-md-5'>Bienvenido a nuestra Clínica de Gastroenterología, un centro especializado en el diagnóstico, tratamiento y prevención de enfermedades del sistema digestivo. Nuestro objetivo es
                  proporcionar una <span className='text-primary'>atención médica de alta calidad</span> y respaldada por lo ultimos avances en la Gastroenterología.</p>
              </div>
            <div className="my-2">
              <div className='doctor-section-content'>

              <div>
                  <button className={`button-menu-doctor ${selectedService === 'Consulta' ? 'active' : ''}`} onClick={() => handleServiceSelect('Consulta')}>Consulta</button>
                  <button className={`button-menu-doctor ${selectedService === 'Estudios' ? 'active' : ''}`} onClick={() => handleServiceSelect('Estudios')}>Estudios</button>
                  <button className={`button-menu-doctor ${selectedService === 'Procedimiento' ? 'active' : ''}`} onClick={() => handleServiceSelect('Procedimiento')}>Procedimiento</button>
                  <button className={`button-menu-doctor ${selectedService === null ? 'active' : ''}`} onClick={() => handleServiceSelect(null)}>Mostrar Todos</button>
                </div>
                <ServiceList serviceToShow={selectedService} moduleToShow="Gastroenterología" />

              </div>
            </div>

        </Container>
      </section>

      <section className="unidades-section">
        <Container fluid>
          <div className="my-5">
              <div className='doctor-section-content'>
                <h2>Conoce a nuestro Equipo Médico:</h2>
                <h4>Contamos con un Equipo de Gastroenterólogos altamente capacitados y con experiencia en diversas Sub Especialidades, Nuestos Médicos estan comprometidos a mantenerse al dia con las ultimas investigaciones
                  y técnicas en el campo de la Gastroenterológia, garantizando así tratamientos basados en evidencias y protocolos actualizados :</h4>
                <div>
                </div>
                <DoctorList specialtyToShow={'Gastroenterología'} groupToShow={'Gastroenterología'} />
              </div>
              <div className='Info-Card-Gastro'>
                
                <h3 id='Informacion-Gastro' className='p-5 h2'>Información sobre sintomas y estudios de la<br></br>Unidad de {pageData.title}:</h3>
                <MenuCardInfo /> 
              </div>

          </div>
        </Container>
      </section>
    </>
  );
};

export default GastroenterologiaPage;
