import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/hero/styles.css';
import ImagenBanner from '../assets/images/logos/Logo Medicci-Negro-Gastro.svg';


function HeroSection() {

  return (
    <section id="hero" className="hero d-flex align-items-center section-bg backgroundHero">
      <Container>
        <Row className="justify-content-between">
        <Col lg={4} order={1} className="order-lg-1 text-lg-start d-flex flex-column align-items-center">
              <img
                className="img-fluid rounded-2 logo-home"
                src={ImagenBanner}
                loading="lazy"
                alt="Foto del médico"
              />
          </Col>
          <Col lg={8} order={2} className="order-lg-2 d-flex flex-column justify-content-center align-items-center align-items-center content-col">
            <h1 data-aos="fade-up">Tu <span className="banner-span-green">salud</span> digestiva <span className='text-primary'> es nuestra </span><span className="banner-span-green">Prioridad</span></h1>
            <p className='fw-4' data-aos="fade-up" data-aos-delay="100">Alta especialidad en todas las ramas de Gástroentelorogía: <span className='banner-span-green'>Clínica Medicci</span></p>
            <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
              {/* <a href="#book-a-table" className="btn-book-a-table">Book a Table</a> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HeroSection;
