import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container} from 'react-bootstrap';
import '../assets/css/Unidades-Medicas/styles.css';
import ContactSection from '../components/Contact';
import HorizontalSection from '../components/HorizontalSection';
import ImagenUnidades from '../assets/images/Unidades.png'

const UnidadesPage = ({ title, description, keywords, canonical }) => (
  
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={canonical} />
      </Helmet>
      <section className="unidades-medicas px-5">
        <Container data-aos="fade-up">
          <div className='my-5'>
            <h1 data-aos="fade-up" className='text-white mb-5'>Especialidades <span className='text-primary'></span></h1>
            <p></p>
          </div>
        </Container>
        <Container className='text-white'>
          <HorizontalSection/>
        </Container>
        <Container fluid>

        <img src={ImagenUnidades} alt='Imagen con especialidades' className="img-fluid rounded-5"/>
        </Container>
      </section>
      <ContactSection></ContactSection>
    
    </>
);

export default UnidadesPage;
