import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/UnidadesPages/styles.css';
import { Link } from 'react-router-dom';
import ServicesData from '../assets/data/Services.json';

import ServiceCard3 from '../components/extras/ServiceCard3.jsx';

const UltrasonidoPage = ({ pageData }) => {

  // const ultrasonidoServices = ServicesData.filter(service => 
  //   service && Array.isArray(service.modules) && service.modules.includes('Ultrasonido')
  // );
  // const servicesToShow = ServicesData.filter(service => service.modules === 'Ultrasonido');   ESTO ES UN String

  const servicesToShow = ServicesData.filter(
    service => Array.isArray(service.modules) && service.modules.includes('Ultrasonido')
  );
  if (!Array.isArray(servicesToShow) || servicesToShow.length === 0) {
    return <p>No se encontraron servicios.</p>;
  }
  
  
  

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>
      <section className="unidades-section">
      
        <Container fluid>
          <h1 className='text-uppercase'>Clínica de {pageData.title}</h1>
          <hr />
          <Row className='p-lg-5 d-flex align-items-center'>
            <Col className='p-lg-5' md={6}>
              <div className="d-flex flex-column">
                <p>El <strong>Ultrasonido Médico</strong> es una herramienta esencial en el diagnóstico no invasivo de diversas enfermedades. Gracias a su tecnología de alta precisión, permite visualizar órganos internos y detectar alteraciones en tiempo real.</p>
                <ul className='d-flex flex-column align-items-start unstyled'>
                  <Link className='text-primary text-decoration-none' to={'/precios'}><i className="bi bi-chevron-right mx-1"></i>Precios Ultrasonido</Link>
                  <Link className='text-primary text-decoration-none' to={'/precios'}><i className="bi bi-chevron-right mx-1"></i>Precios Diagnósticos</Link>
                  <Link className='text-primary text-decoration-none' to={'/precios'}><i className="bi bi-chevron-right mx-1"></i>Precios Ultrasonido Pélvico</Link>
                </ul>
              </div>
            </Col>
            <Col className='p-5 align-items-center' md={6}>
              <div className="info-item d-flex justify-content-around">
                <img className='img-fluid rounded-4' width={500} src="https://unidad-gastroenterologia.com/static/images/ultrasonido-abdominal.jpg" alt="Imagen de ultrasonido" />
              </div>
            </Col>
          </Row>
          <hr />
        </Container>

        <Container className='text-start check-section'>
          <h4>Beneficios del Ultrasonido Médico:</h4>
          <ul className='unstyled'>
            <li><i className="bi bi-chevron-right mx-1"></i> Diagnóstico rápido y preciso sin procedimientos invasivos.</li>
            <li><i className="bi bi-chevron-right mx-1"></i> Visualización detallada de órganos como hígado, riñones, útero y próstata.</li>
            <li><i className="bi bi-chevron-right mx-1"></i> Detección temprana de enfermedades como cálculos biliares, quistes ováricos y tumores.</li>
            <li><i className="bi bi-chevron-right mx-1"></i> Seguimiento médico de condiciones preexistentes o tratamientos.</li>
          </ul>
        </Container>

        <Container fluid>
          <div className='service-section p-4'>
            <h4>Servicios que ofrecemos en la <br />Unidad de {pageData.title}:</h4>
            <p className='p-2 p-md-5'>
              Nuestra Unidad de <span className='text-primary fw-bold'>Ultrasonido</span> cuenta con equipos de última generación y médicos altamente capacitados. Ofrecemos servicios personalizados para garantizar un diagnóstico eficiente y preciso.
            </p>
            <ul className='unstyled flex-column text-start px-2'>
              <li className='d-flex align-items-center px-4'><i className="bi bi-check-circle text-success fz-1-3 mx-3"></i> Ultrasonido Abdominal Superior y Completo.</li>
              <li className='d-flex align-items-center px-4'><i className="bi bi-check-circle text-success fz-1-3 mx-3"></i> Ultrasonido Pélvico para hombres y mujeres.</li>
              <li className='d-flex align-items-center px-4'><i className="bi bi-check-circle text-success fz-1-3 mx-3"></i> Ultrasonido Endoanal para detección de patologías rectales.</li>
            </ul>
          </div>



          {/* <Row className='d-flex flex-column justify-content-center'>
            {ultrasonidoServices
              .filter(service => service.modules)
              .map(service => (
                <Col key={service.id} md={10} className="d-flex">
                  <ServiceCard3 {...service} onClick={() => console.log(`Clicked ${service.name}`)} />
                </Col>
              ))}
          </Row> */}
          <ServiceCard3 services={servicesToShow} />
          
          <p className='text-primary text-center mt-4'>
            Todos los estudios son realizados por especialistas certificados y entregados rapidamente.
          </p>

          <div className="cta-section mx-3 mx-lg-5">
            <h4>¡Reserva tu cita de Ultrasonido ahora!</h4>
            <p className='text-center'>Obtén un diagnóstico preciso y comienza tu tratamiento con la confianza de nuestros especialistas en ultrasonido.</p>
            <button className='btn-general' onClick={() => window.open('https://api.whatsapp.com/send?phone=5534816054', '_blank')}>Agenda tu Ultrasonido</button>
          </div>

        </Container>
      </section>
    </>
  );
};

export default UltrasonidoPage;
