
// EndoscopíaPage.jsx
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/UnidadesPages/styles.css';
import ServiceList from '../components/ServiceList';
// import DoctorList from '../components/DoctorList';
import EndoscopiaCards from '../components/EndoscopiaCards.jsx';

const EndoscopíaPage = ({ pageData }) => {
  const [selectedService, setSelectedService] = useState(null);

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>

      <section style={{ backgroundImage: `url(${pageData.image})` }} className="unidades-section">
      <Container>
            <div className="my-5">
                <div className="my-5">
                <h1>{pageData.title}</h1>
                <hr />
                <Row className='p-lg-5 d-flex align-items-center'>
                    <Col className='p-lg-5' md={6}>
                    <div className="info-item d-flex align-items-center">
                        <div>
                        <p>Método invasivo de diagnóstico y tratamiento intervencionista de enfermedades del Aparato Digestivo Alto (Esófago, Estomago y Duodeno) o Digestivo Bajo (Colon y Recto) en la que se introduce una fibra óptica a través de la boca (panendoscopia) o del Ano (Colonoscopia).</p>
                        </div>
                    </div>
                    </Col>
                    <Col className='p-5 align-items-center' md={6}>
                    <div className="info-item d-flex justify-content-around">
                        <div>
                        <img className='img-fluid rounded-4' width={500} src="https://unidad-gastroenterologia.com/images/clinica-de-balon-gastrico-header.jpg" alt="Imagen de Endoscopia Digestiva" />
                        </div>
                    </div>
                    </Col>
                    </Row>
                </div>
                <hr />
            </div>
        </Container>

        <Container fluid>
        <div className='doctor-section-content'>
            
                <h2>Endoscopia Gastrointestinal</h2>
                <p>La endoscopía digestiva es una herramienta valiosa en el diagnostico y tratamiento de enfermedades gastrointestinales.</p>
                <p>La tecnología en endoscopia digestiva ha avanzado significativamente en los ultimos años, permitiendo realizar diagnósticos mas precisos a través de la cromoendoscopia y tratamientos con innovaciones relevantes, como el uso de: radiofrecuencia, argon plasma, láser CO2 y ultimamente la introduccion de <span className='fw-semibold'>Sutura Endoscopica.</span></p>
              </div>
          <div className="my-5">
            <div className="my-5">
              <div className='doctor-section-content'>

                <div>
                  <button className={`button-menu-doctor ${selectedService === 'Consulta' ? 'active' : ''}`} onClick={() => handleServiceSelect('Consulta')}>Consulta</button>
                  <button className={`button-menu-doctor ${selectedService === 'Estudios' ? 'active' : ''}`} onClick={() => handleServiceSelect('Estudios')}>Estudios</button>
                  <button className={`button-menu-doctor ${selectedService === null ? 'active' : ''}`} onClick={() => handleServiceSelect(null)}>Mostrar Todos</button>
                </div>
                <ServiceList serviceToShow={selectedService} moduleToShow="Endoscopia Digestiva" />
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className='p-5'>
        <Container>
          <h4 className='text-primary h2'>Procedimientos Terapéuticos Endoscópicos</h4>
          <EndoscopiaCards />
        </Container>
      </section>
    </>
  );
};

export default EndoscopíaPage;
