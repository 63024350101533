import React, { useEffect } from 'react';
import { Container, Row, Card, Button } from 'react-bootstrap';
import '../assets/css/HorizontalSlider/styles.css';
import '../assets/css/PreciosSection/styles.css';
import IconoEstudios from '../assets/images/Iconos/Estudios-icono.webp';

function HorizontalSection() {
  useEffect(() => {
    // Animación para hacer scroll horizontal a cada elemento con la clase horizontal-item-menu
    const horizontalItems = document.querySelectorAll('.horizontal-item-menu');
    const container = document.querySelector('.container-horizontal');

    let isScrolling = false;

    const scrollToItem = (index) => {
      const item = horizontalItems[index];
      if (item) {
        container.scrollTo({
          left: index * item.offsetWidth,
          behavior: 'smooth', // Scroll suave sin GSAP
        });
      }
    };

    // Evento de scroll para activar el desplazamiento
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const containerTop = container.offsetTop;
      const containerHeight = container.offsetHeight;
      const windowHeight = window.innerHeight;

      // Activar el desplazamiento cuando el centro del contenedor esté al centro de la ventana
      const containerCenter = containerTop + containerHeight / 1.5;
      const windowCenter = scrollTop + windowHeight / 1.5;

      if (scrollTop + windowHeight > containerTop && windowCenter >= containerCenter && !isScrolling) {
        isScrolling = true;
        scrollToItem(0); // Desplaza al primer ítem como ejemplo
      } else if (scrollTop + windowHeight <= containerTop) {
        isScrolling = false;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container className='d-flex justify-content-center horizontal'>
      <Row className="container-horizontal">
        <div className="horizontal-section">
          <div className="horizontal-item-text">
            <h1 data-aos="fade-up" data-aos-delay="100" className='text-wrap'>Conoce nuestras Áreas de especialidad en <br /><span className='banner-span-green'>Clínica Medicci</span></h1>
            <hr />
            <p className='text-wrap'>Visita nuestras principales clínicas de la Ciudad de México.</p>
          </div>
          <div className="horizontal-item horizontal-item-menu gastroenterologia">
            <Card.Body>
              <img className='img-fluid image-precios' src={IconoEstudios} alt="Icono referente a Estudio Médico" />
              <Card.Title className="text-primary text-uppercase text-center fs-5">CLÍNICA DE GASTROENTEROLOGÍA</Card.Title>
              <hr />
              <p className='text-wrap'>Especialidad Médica dedicada al tratamiento de enfermedades del tubo digestivo (esófago, estómago, intestino delgado) y glándulas asociadas (hígado, páncreas).</p>
              <div className="d-grid">
                <Button variant="primary" className="text-uppercase">Leer más</Button>
              </div>
            </Card.Body>
          </div>
          <div className="horizontal-item horizontal-item-menu coloproctologia">
            <Card.Body>
              <img className='img-fluid image-precios' src={IconoEstudios} alt="Icono referente a Estudio Médico" />
              <Card.Title className="text-primary text-uppercase text-center fs-5">CLÍNICA DE COLOPROCTOLOGÍA</Card.Title>
              <hr />
              <p className='text-wrap'>La Coloproctología es la especialidad médica dedicada al diagnóstico y tratamiento de las enfermedades del colon, recto y ano.</p>
              <div className="d-grid">
                <Button variant="primary" className="text-uppercase">Leer más</Button>
              </div>
            </Card.Body>
          </div>
          <div className="horizontal-item horizontal-item-menu endoscopia">
            <Card.Body>
              <img className='img-fluid image-precios' src={IconoEstudios} alt="Icono referente a Estudio Médico" />
              <Card.Title className="text-primary text-uppercase text-center fs-5">CLÍNICA DE ENDOSCOPÍA DIGESTIVA</Card.Title>
              <hr />
              <p className='text-wrap'>Método invasivo de diagnóstico y tratamiento intervencionista de enfermedades del Aparato Digestivo Alto (Esófago, Estómago y Duodeno) o Digestivo Bajo (Colon y Recto)</p>
              <div className="d-grid">
                <Button variant="primary" className="text-uppercase boton-horizontal">Leer más</Button>
              </div>
            </Card.Body>
          </div>
        </div>
      </Row>
    </Container>
  );
}

export default HorizontalSection;
