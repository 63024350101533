import React from 'react';
import PropTypes from 'prop-types';

const ServiceCard = ({ id, name, title, service, photoUrl, group, link, onClick }) => (
  <div className={`card-doctor doctor-card ${group || 'default-group'}`} onClick={onClick}>
    <img src={photoUrl || 'https://via.placeholder.com/150'} className="card-img-top img-fluid" alt={`Imagen de ${name}`} />
    <div className="card-body">
      <h5 className="card-title text-uppercase">{name || 'Servicio Desconocido'}</h5>
      {/* <p className="card-text">{title || 'Título no disponible'}</p> */}
      <p className="card-text"><small className="text-muted">{service || 'Servicio no especificado'}</small></p>
    </div>
  </div>
);

ServiceCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  service: PropTypes.string,
  photoUrl: PropTypes.string,
  group: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func
};

ServiceCard.defaultProps = {
  title: 'Título no disponible',
  service: 'Servicio no especificado',
  photoUrl: 'https://via.placeholder.com/150',
  group: 'default-group',
  link: '#',
  onClick: () => {}
};


export default ServiceCard;
