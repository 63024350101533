import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import '../../assets/css/button/dark.css';

const DarkButton = ({ toggleScroll, handleNavLinkClick }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    const currentTheme = document.documentElement.getAttribute('data-bs-theme');
    const invertedTheme = currentTheme === 'dark' ? 'light' : 'dark';
    document.documentElement.setAttribute('data-bs-theme', invertedTheme);

    // Cambiar el color de los elementos SVG
    const letrasSVG = document.querySelectorAll('.letras-svg');
    letrasSVG.forEach(function(elemento) {
      // Reemplaza 'blanco' o 'negro' con el color que desees
      const nuevoColor = isDarkMode ? '#fff' : '#000000';
      elemento.setAttribute('fill', nuevoColor);
    });

    setIsDarkMode(!isDarkMode);
    toggleScroll(); // Llama a la función toggleScroll del padre
    handleNavLinkClick(); // Colapsa el navbar
  };

  return (
    <Button variant={isDarkMode ? 'dark' : 'light'} className="dark-button" onClick={toggleTheme}>
      {isDarkMode ? <i className="bi bi-sun-fill h4"></i> : <i className="bi bi-moon-fill text-secondary h4"></i>}
    </Button>
  );
};

export default DarkButton;
