import React, { useState } from 'react';

function CalculatorIMC() {
    const [peso, setPeso] = useState('');
    const [altura, setAltura] = useState('');
    const [resultado, setResultado] = useState(null);

    const calcularIMC = () => {
        if (peso && altura) {
            const alturaEnMetros = parseFloat(altura) / 100; // Convertir altura a metros
            const IMC = parseFloat(peso) / (alturaEnMetros * alturaEnMetros);
            setResultado(IMC.toFixed(2)); // Redondear a dos decimales
        } else {
            setResultado('Por favor, ingrese ambos valores.');
        }
    };

    return (
        <div>
            <h1>CalculatorIMC</h1>
            <p>Crear una calculadora de IMC dinámica</p>
            <input 
                type="text" 
                value={peso} 
                onChange={(e) => setPeso(e.target.value)} 
                placeholder="Ingrese su peso" 
            />
            <br />
            <input 
                type="text" 
                value={altura} 
                onChange={(e) => setAltura(e.target.value)} 
                placeholder="Ingrese su altura (cm)" 
            />
            <br />
            <button onClick={calcularIMC}>Calcular</button>
            {resultado && <p>IMC: {resultado}</p>}
        </div>
    );
}

export default CalculatorIMC;
