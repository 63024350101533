import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesArray } from './RoutesConfig';

// Función de Levenshtein
const levenshtein = (a, b) => {
  const tmp = [];
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  for (let i = 0; i <= b.length; i++) tmp[i] = [i];
  for (let j = 0; j <= a.length; j++) tmp[0][j] = j;

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      tmp[i][j] = b.charAt(i - 1) === a.charAt(j - 1)
        ? tmp[i - 1][j - 1]
        : Math.min(tmp[i - 1][j - 1] + 1, Math.min(tmp[i][j - 1] + 1, tmp[i - 1][j] + 1));
    }
  }
  return tmp[b.length][a.length];
};

// Buscar la ruta más cercana
const findClosestRoute = (path, routes) => {
  let closestRoute = routes[0].path;
  let minDistance = levenshtein(path, routes[0].path);

  for (let i = 1; i < routes.length; i++) {
    const distance = levenshtein(path, routes[i].path);
    if (distance < minDistance) {
      minDistance = distance;
      closestRoute = routes[i].path;
    }
  }
  return { closestRoute, minDistance };
};

const DISTANCE_THRESHOLD = 3; // Umbral de distancia para redirección

const Redirector = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;

    // Encontrar la ruta más cercana
    const { closestRoute, minDistance } = findClosestRoute(path, routesArray);

    if (minDistance > DISTANCE_THRESHOLD) {
      // Si la distancia es mayor al umbral, redirige a 404
      navigate('/404', { replace: true });
    } else {
      // Redirige a la ruta más cercana
      navigate(closestRoute, { replace: true });
    }
  }, [location, navigate]);

  return null;
};

export default Redirector;
