import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// import ContactSection from '../components/Contact';
import '../assets/css/PreciosSection/styles.css';
import AOS from 'aos';

const ContactPage = ({ title, description, keywords, canonical }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta name='keywords' content={keywords} />
        <link rel='canonical' href={canonical} />
      </Helmet>
      <header>
        {/* Contenido del encabezado aquí */}
      </header>
      {/* Resto del contenido de la página */}
    </>
  );
};

export default ContactPage;
