const gastroenterologiaPagesData = {
  Gastroenterologia: {
    title: "Gastroenterología",
    description: "Información médica sobre diagnostico y tratamiento de enfermedades relacionados con Gastroenterologia, Cirugia General, y Cirugia Laparoscopica. En ella encontraras información sobre enfermedades como Colitis, gastritis, reflujo, hernia hiatal, colecistitis y otras enfermedades relacionadas con la gastroenterología.",
    keywords: "Inicio, Página principal Gastroenterología, Gastroenterologia, Cirugia General, Cirugia Laparoscopica",
    canonical: "https://unidad-gastroenterologia.com/Laparoscopia",
    sections: [
      {
        titleSection: "CLÍNICA DE GASTROENTEROLOGÍA",
        content: ["Los grandes avances científicos en Gastroenterología han hecho que la actualmente se subdivida en ramas de alta especialidad dedicadas estrictamente a un área específica del tubo digestivo o métodos diagnósticos y terapéuticos, o cirugía de minima invación dedicados a resolver todos los padecimientos que un paciente es asi que en Medicci: Unidad de Gastroenterología y Endoscopia Digestiva contamos con todas las especialidades necesarias para el cuidado de su salud digestiva."]
      },
      {
        titleSection: "SINTOMAS FRECUENTES DE LA CONSULTA DE GASTROENTEROLOGÍA",
        content: [""],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/gastroenterologia-laparoscopica-1.gif"
      },
      {
        titleSection: "Hemorragia Digestiva",
        content: ["La hematemesis o vómito francamente hemático se relaciona con hemorragia masiva del tracto gastrointestinal alto(estomago, esófago y duodeno), el vómito de contenido fino, granular, oscuro, es el llamado vómito en borra de café, habla de una hemorragia activa o reciente, pero en grado menos severo La Melena o heces líquidas, negras y malolientes representan la degradación de la sangre que a atravesado el intestino delgado y grueso. La Hematoquecia o el pasaje de sangre vinosa o marrón y coágulos frescos por el recto es típica del colon."]
      },
      {
        titleSection: "Icteria",
        content: ["La icteria es una condición en la que el cuerpo no puede producir los nutrientes necesarios para el crecimiento y desarrollo de los organismos. La icteria se produce cuando el cuerpo no tiene suficientes nutrientes para producir los nutrientes que necesitan los organismos para crecer. La icteria puede ser causada por una variedad de factores, como la falta de nutrientes, la exposición a los agentes químicos, el estrés, la falta de sueño, la falta de actividad física, la falta de ejercicio, y la exposición a la luz. La icteria puede tener un impacto negativo en la salud, ya que puede causar problemas de salud como la diabetes, la hipertensión, la obesidad, y la enfermedad cardíaca."]
      },
      {
        titleSection: "Diarrea y Estreñimiento",
        content: ["Los cambios en los hábitos intestinales es un síntoma difícil de definir. El ritmo defecatorio normal depende de tantos factores, que cualquier indicio de cambio ser investigado a fondo. Es importante definir que un estreñimiento es cuando la evacuación es sólida, difícil de pasar y esporádica."]
      },
      {
        titleSection: "Dolor abdominal",
        content: ["Para determinar el dolor se divide al abdomen en 4 cuadrantes. El dolor correspondiente a Cuadrante Superior Derecho corresponde a Colecistitis o inflamación de Vesícula Biliar inflamaciones de Hígado. En Cuadrante Superior Izquierdo pueden deberse a Gastritis, Pancreatitis. En Cuadrante Inferior Derecho se debe sospechar Apendicitis y en el Cuadrante Inferior Izquierdo Diverticulitis por divertículos en el colon. Cada dolor tiene características especificas y una forma de presentación cronológica que se deben acompañar de estudios de imagen y gabinete. El aparato digestivo con el aparato urinario."]
      },
      {
        titleSection: "Nauseas y Vómito",
        content: ["La Nausea es una sensación displacentera, referida a la faringe y al abdomen superior y con la percepción de que el vomito es inminente El vómito es la expulsión oral forzada del contenido gástrico. Los síntomas agudos pueden ser indicativos de catástrofes abdominales como apendicitis aguda."]
      }
    ]
  },
  Colecistectomia: {
    title: "Colecistectomía",
    description: "Procedimiento quirúrgico para extirpar la vesícula biliar...",
    keywords: "colecistectomía, cirugía, vesícula biliar",
    canonical: "https://unidad-gastroenterologia.com/colecistectomia",
    image: "/assets/images/Unidades/colecistectomia-banner.webp",
    sections: [
      {
        titleSection: "Introducción",
        content: ["La colecistectomía es un procedimiento quirúrgico para extirpar la vesícula biliar. Este procedimiento es común y tiene un bajo riesgo de complicaciones."]
      }
      // Añadir más secciones según sea necesario
    ]
  },
  Funduplicatura: {
    title: "Funduplicatura",
    description: "Procedimiento para tratar el reflujo gastroesofágico...",
    keywords: "funduplicatura, cirugía, reflujo gastroesofágico",
    canonical: "https://unidad-gastroenterologia.com/funduplicatura",
    image: "/assets/images/Unidades/funduplicatura-banner.webp",
    sections: [
      {
        titleSection: "Funduplicatura",
        content: [
          "La funduplicatura es un procedimiento quirúrgico utilizado para tratar el reflujo gastroesofágico (ERGE). Este procedimiento fortalece la válvula entre el esófago y el estómago.",
          "Antes de tomar la decisión de realizar un procedimiento anti reflujo, la presencia de reflujo gastro-esofágico con todas las complicaciones que acompañan, como la esofagitis por reflujo o la formación de estenosis debe ser confirmada.",
          "La deglución de bario preoperatorio debe ser revisada para decartar esófago corto, estenosis esofágica.",
          "El vaciamiento gástrico y la pHmetria de 24 horas deben ser monitoreadas para correlación de dolor del paciente con episodios de reflujo gástrico.",
          "Debido al uso de medicamentos como la ranitidina o el omeprazol la aclorhidria gástrica puede permitir el sobrecrecimiento bacteriano, y por lo tanto los antibióticos peri operatorios deben ser administrados."
        ],
        // image: "/assets/images/Unidades/funduplicatura.webp"
      },
      {
        titleSection: "Preparación Preoperatoria",
        content: [
          "Antes de tomar la decisión de realizar un procedimiento anti reflujo, la presencia de reflujo gastro-esofágico con todas las complicaciones que acompañan, como la esofagitis por reflujo o la formación de estenosis debe ser confirmada.",
          "La deglución de bario preoperatorio debe ser revisada para decartar esófago corto, estenosis esofágica.",
          "El vaciamiento gástrico y la pHmetria de 24 horas deben ser monitoreadas para correlación de dolor del paciente con episodios de reflujo gástrico.",
          "Debido al uso de medicamentos como la ranitidina o el omeprazol la aclorhidria gástrica puede permitir el sobrecrecimiento bacteriano, y por lo tanto los antibióticos peri operatorios deben ser administrados."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica.gif"
      },
      {
        titleSection: "Posición",
        content: ["El paciente se coloca en la posición de litotomía para permitir al cirujano operar entre las piernas del paciente. Esto permite un cómodo acceso al hiato esofágico."],
        // image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-1.gif"
      }
    ]
  },
  Apendicectomia: {
    title: "Apendicectomia",
    description: "Procedimiento para tratar el reflujo gastroesofágico...",
    keywords: "funduplicatura, cirugía, reflujo gastroesofágico",
    canonical: "https://unidad-gastroenterologia.com/funduplicatura",
    image: "/assets/images/Unidades/funduplicatura-banner.webp",
    sections: [
      {
        titleSection: "Apendicectomía",
        content: [
          "La apendicectomía es una técnica quirúrgica utilizada para extraer el apéndice, un pequeño tubo con forma de dedo que se encuentra entre el intestino delgado y el grueso.",
          "Se realiza principalmente en casos de apendicitis aguda, cuando el apéndice está inflamado o infectado. La cirugía se lleva a cabo bajo anestesia general y puede realizarse de dos maneras:",
          "Apendicectomía abierta: Se hace una incisión en el lado derecho del abdomen y se extirpa el apéndice.",
          "Apendicectomía laparoscópica: Se realizan tres o cuatro pequeñas incisiones en el abdomen para quitar el apéndice de forma menos invasiva.",
        ],
      },
      {
        titleSection: "Preparación Preoperatoria",
        content: [
          "Una vez que se determina el diagnóstico de la apendicitis aguda y se toma la decisión de explorar al paciente, es imperativo hidratar al paciente de forma adecuada con líquidos intravenosos. Se debe administrar antibióticos por vía intravenosa.",
          "En los pacientes jóvenes, los estudios de gabinete son mínimos cuando el cuadro clínico es muy sospechoso de apendicitis.",
          "Si la masa es palpable en el cuadrante inferior derecho, la tomografía computarizada de abdomen y de pelvis puede ser valiosa para evaluar la presencia de absceso apendicular o bien un tumor maligno que se origina desde el ciego, el apéndice o el intestino delgado.",
          "Dependiendo de la experiencia del radiólogo, a menudo un ultrasonido puede ser utilizado para la visualización del apéndice engrosado"
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica.gif"
      },
      {
        titleSection: "Establecimiento de Neumoperitoneo",
        content: ["La insuflación intra-abdominal de CO2 es establecida en la forma habitual a través del puerto peri umbilical. El paciente se coloca en la posición de Trendelenburg para mejorar la visualización de la pelvis y el abdomen inferior.",
          "Por norma se realiza de examen de diagnóstico laparoscópico de toda la cavidad abdominal."
        ],
        // image: "https://unidad-gastroenterologia.com/images/laparoscopia/funduplicatura-laparoscopica-1.gif"
      },
      {
        titleSection: "Colocación de los Puertos",
        content: ["Dos puertos están colocados, un puerto de 10-mm en la zona suprapúbica y un puerto de 5 mm en el cuadrante inferior izquierdo. Ambos están dirigidos hacia el apéndice."
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/apendicectomia-laparoscopica-1.gif"
      },
      {
        titleSection: "Técnica Quirúrgica",
        content: ["Con una técnica de dos manos, el cirujano expone el apéndice con el uso de una pinza atraumática. Una vez localizado, la punta del apéndice se toma con una pinza atraumática y se la levanta, hacia la pared abdominal.",
        "El meso apéndice se diseca cerca de la base del apéndice con una pinza curva y se liga, se engrapa o se corta con bisturí armónico.",
        "La base del apéndice se libera cuidadosamente de cualquier tejido adiposo que lo rodee y se seccionado en su base con engrapadota, corte y endolup o ligadura transfictiva endocorporal.",
        "El apéndice extirpado se coloca dentro de una bolsa de extracción.",
        "El cuadrante inferior derecho se riega con solución salina para eliminar cualquier material contaminado.",
        "Si se encuentra alguna dificultad durante la disección, el procedimiento debe ser convertido a una apendicetomía abierta.",
        ],
        image: "https://unidad-gastroenterologia.com/images/laparoscopia/apendicectomia-laparoscopica-1.gif"
      },
      {
        titleSection: "Cierre",
        content: ["Los defectos de la aponeurosis se cierran con puntos en cruz o en ocho con sutura 0-0 absorbibles y la piel se cierra con sub-dérmico de dermalon 3/0.",
        ]
      }
    ]
  },
  // Añadir más páginas aquí
};

export default gastroenterologiaPagesData;
