import React, { useState } from 'react';
import { staticRoutes, routesArray } from '../components/RoutesConfig';

const Sitemap = () => {
  const domain = 'https://unidad-gastroenterologia.com';
  const [selectedContent, setSelectedContent] = useState('');

  // Generar texto plano para el archivo `sitemap.txt`
  const generateSitemapText = () => {
    const allRoutes = [
      ...staticRoutes.map((route) => `${domain}${route.path}`),
      ...routesArray.map((route) => `${domain}${route.path}`),
    ];
    const uniqueRoutes = [...new Set(allRoutes)];
    return uniqueRoutes.join('\n');
  };

  // Generar enlaces <a> para sitemap.txt
  const generateSitemapLinks = () => {
    const allRoutes = [
      ...staticRoutes.map((route) => `${domain}${route.path}`),
      ...routesArray.map((route) => `${domain}${route.path}`),
    ];
    const uniqueRoutes = [...new Set(allRoutes)];

    return uniqueRoutes.map((url, index) => (
      <a
        href={url}
        key={index}
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'block', margin: '5px 0' }}
      >
        {url}
      </a>
    ));
  };

  // Generar contenido para `.htaccess`
  const generateHtaccess = () => {
    const allRoutes = [
      ...staticRoutes.map((route) => route.path),
      ...routesArray.map((route) => route.path),
    ];

    const uniqueRoutes = [...new Set(allRoutes)];
    const filteredRoutes = uniqueRoutes.filter((path) => path !== '/');

    let htaccessContent = `<IfModule mod_rewrite.c>
  RewriteEngine On
  RewriteBase /

  # Redirecciones 301 de .html a las nuevas rutas sin .html
`;

    filteredRoutes.forEach((path) => {
      const cleanPath = path.startsWith('/') ? path.substring(1) : path;
      htaccessContent += `  RewriteCond %{REQUEST_URI} ^/${cleanPath}.html$ [NC]\n`;
      htaccessContent += `  RewriteRule ^${cleanPath}.html$ ${path} [R=301,L]\n\n`;
    });

    htaccessContent += `
  # Si el archivo o directorio solicitado no existe
  RewriteCond %{REQUEST_FILENAME} !-f
  RewriteCond %{REQUEST_FILENAME} !-d
  RewriteRule ^ index.html [L]
</IfModule>
`;

    return htaccessContent;
  };

  // Generar contenido para el archivo XML
  const generateSitemapXML = () => {
    const allRoutes = [
      { path: '/', priority: '1', changefreq: 'weekly' },
      { path: '/index', priority: '0.9', changefreq: 'weekly' },
      ...staticRoutes.map((route) => ({
        path: route.path,
        priority: '0.8',
        changefreq: 'weekly',
      })),
      ...routesArray.map((route) => ({
        path: route.path,
        priority: '0.8',
        changefreq: 'weekly',
      })),
    ];

    // Ordenar por `priority` de mayor a menor
    const sortedRoutes = allRoutes.sort((a, b) => parseFloat(b.priority) - parseFloat(a.priority));

    let xmlContent = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:image="http://www.google.com/schemas/sitemap-image/1.1">
`;

    sortedRoutes.forEach((route) => {
      const url = `${domain}${route.path}`;
      const lastmod = new Date().toISOString().split('T')[0];
      xmlContent += `  <url>
    <loc>${url}</loc>
    <lastmod>${lastmod}T00:00:00+00:00</lastmod>
    <changefreq>${route.changefreq}</changefreq>
    <priority>${route.priority}</priority>
  </url>
`;
    });

    xmlContent += `</urlset>`;
    return xmlContent;
  };

  // Descargar archivo basado en el contenido seleccionado
  const downloadFile = () => {
    let fileName = '';
    let fileContent = '';
    let fileType = 'text/plain';

    if (selectedContent === generateSitemapText()) {
      fileName = 'sitemap.txt';
      fileContent = generateSitemapText();
    } else if (selectedContent === generateHtaccess()) {
      fileName = '.htaccess';
      fileContent = generateHtaccess();
    } else if (selectedContent === generateSitemapXML()) {
      fileName = 'sitemap.xml';
      fileContent = generateSitemapXML();
      fileType = 'application/xml';
    }

    const blob = new Blob([fileContent], { type: fileType });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  return (
    <div className="container my-5">
      <h1>Sitemap</h1>
      <p>
        A continuación, puedes generar un archivo <code>sitemap.txt</code>, <code>.htaccess</code> o <code>sitemap.xml</code> basado en las rutas de tu aplicación:
      </p>

      {/* Botones para mostrar el contenido */}
      <div className="mb-4">
        <button className="btn-general me-2" onClick={() => setSelectedContent(generateSitemapText())}>
          Ver sitemap.txt
        </button>
        <button className="btn-general me-2" onClick={() => setSelectedContent(generateHtaccess())}>
          Ver .htaccess
        </button>
        <button className="btn-general" onClick={() => setSelectedContent(generateSitemapXML())}>
          Ver sitemap.xml
        </button>
      </div>

      {/* Mostrar el contenido generado o los enlaces */}
      <h2>Contenido seleccionado:</h2>
      {selectedContent === generateSitemapText() ? (
        <div>{generateSitemapLinks()}</div>
      ) : (
        <pre
          style={{
            whiteSpace: 'pre-wrap',
            backgroundColor: '#f8f9fa',
            padding: '15px',
            borderRadius: '5px',
            minHeight: '200px',
          }}
        >
          {selectedContent || 'Selecciona una opción para ver su contenido aquí.'}
        </pre>
      )}

      {/* Botón para descargar el contenido mostrado */}
      {selectedContent && (
        <button className="btn-general mt-3" onClick={downloadFile}>
          Descargar archivo
        </button>
      )}
    </div>
  );
};

export default Sitemap;
