import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import NavBar from './components/NavBar';
import RoutesConfig from './components/RoutesConfig';
import ScrollToTop from './components/ScrollTop';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { locations } from './components/extras/Direcciones';
import BotonWsp from './components/extras/BotonWsp';

// Lazy load para componentes que no se necesitan de inmediato
const ContactSection = lazy(() => import('./components/Contact'));
const Footer = lazy(() => import('./components/Footer'));

const whatsappNumber = locations.location1.whatsapp;
const phoneNumber = locations.location1.phone;

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <HelmetProvider>
        <div className="App">
          <NavBar />
          <RoutesConfig />
          {/* Botón de WhatsApp cargado inmediatamente */}
          <BotonWsp whatsappNumber={whatsappNumber} phoneNumber={phoneNumber} />
          {/* Suspense para cargar componentes menos críticos */}
          <Suspense fallback={<div>Cargando...</div>}>
            <ContactSection />
            <Footer />
          </Suspense>
        </div>
      </HelmetProvider>
    </Router>
  );
}

export default App;
