import React from 'react';
import PropTypes from 'prop-types';

const DoctorCard = ({ id, name, title, specialty, photoUrl, group, onClick }) => (
  <div onClick={onClick} className={`card-doctor doctor-card ${group}`}>
    <img src={photoUrl} className="card-img-top img-fluid" alt={`Imagen de ${name}`} />
    <div className="card-body">
      <h5 className="card-title">{name}</h5>
      <p className="card-text">{title}</p>
      <p className="card-text"><small className="text-muted">{specialty}</small></p>
    </div>
  </div>
);

DoctorCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  specialty: PropTypes.string.isRequired,
  photoUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default DoctorCard;
