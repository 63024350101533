import React from 'react';
import { Row, Col } from 'react-bootstrap';

const coloproctologiaData = [
  {
    title: 'Ligadura de Hemorroides',
    image: 'https://unidad-gastroenterologia.com/images/ligadura-de-hemorroides.jpg',
    link: '/inicio',
  },
  {
    title: 'Cirugía TDH y Laser de Hemorroides',
    image: 'https://unidad-gastroenterologia.com/images/cirugia-tdh-y-laser-de-hemorroides.jpg',
    link: '/ulcera',
  },
  {
    title: 'Cirugía VAAFT - Fistula Anal',
    image: 'https://unidad-gastroenterologia.com/images/cirugia-vaaft-fistula-anal.jpg',
    link: '/inicio',
  },
  {
    title: 'Resección de Polipos con ASA y disección submucosa',
    image: 'https://unidad-gastroenterologia.com/images/polipectomia-con-asa.jpg',
    link: '/polipectomia-y-mucosectomia-endoscopica',
  },
  {
    title: 'Cirugía Laparoscopía de Colon',
    image: 'https://unidad-gastroenterologia.com/images/cirugia-laparoscopica-de-colon.jpg',
    link: '/inicio',
  }
  ,
  {
    title: 'Tratamiento con Radiofrecuencia Secca para incontinencia fecal',
    image: 'https://unidad-gastroenterologia.com/images/tratamiento-con-radiofrecuencia-secca-para-incontinencia-fecal.jpg',
    link: '/tratamiento-radiofrecuencia-secca',
  }
];

// Componente para renderizar las tarjetas
const ColoproctologiaCards = () => {
  return ( 

 
    <Row className='mt-5'>
      {coloproctologiaData.map((coloproctologia, index) => (
        <Col key={index} md={4} className='mt-4'> {coloproctologia.external ? ( 
        <a href={coloproctologia.link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`${coloproctologia.title} (abre en una nueva pestaña)`}
        className="Cards-Endoscopia">
            <img src={coloproctologia.image} alt={`Icono de ${coloproctologia.title}`} className="img-fluid" />
            <p>{coloproctologia.title}</p>
        </a>

         ) :  (
            <a href={coloproctologia.link} className='Cards-Endoscopia'>
                <img src={coloproctologia.image} alt={`Icono de ${coloproctologia.title}`} className="img-fluid" />
                <p>{coloproctologia.title}</p>
            </a>)}
        </Col>
      ))}
    </Row>
  );
};

export default ColoproctologiaCards;
