import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/DynamicPages/styles.css';

const DynamicPage = ({ pageData }) => {
  if (!pageData) {
    return <div>Contenido no disponible</div>;
  }


  return (
    <>
      {/* Configuración del SEO con Helmet */}
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>

      {/* Contenido dinámico */}
      <section className={`dynamic-page-section ${pageData.className || ''}`}>
        <Container>
          <div className="my-5">
            {pageData.sections &&
              pageData.sections.map((section, index) => (
                <div key={index} className="mb-5">
                  {/* Títulos dinámicos: H1 para la primera sección, H2 para las demás */}
                  {index === 0 ? (
                    <>
                      <h1 className="section-title">{section.titleSection}</h1>
                      {/* Renderiza el autor si pertenece a Publicaciones */}
                      {pageData.author && (
                        <p className="author-info">Autor: {pageData.author}</p>
                      )}
                    </>
                  ) : (
                    <h2 className="section-title">{section.titleSection}</h2>
                  )}

                  {/* Contenido y diseño condicional */}
                  <Row className="align-items-center">
                    {section.image ? (
                      index % 2 === 0 ? (
                        <>
                          <Col md={8}>
                            {section.content.map((paragraph, i) => (
                              <p key={i}>{paragraph}</p>
                            ))}
                          </Col>
                          <Col md={4}>
                            <img
                              src={section.image}
                              alt={section.titleSection}
                              className="img-fluid mb-3 rounded-5"
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col md={8}>
                            {section.content.map((paragraph, i) => (
                              <p key={i}>{paragraph}</p>
                            ))}
                          </Col>
                          <Col md={4}>
                            <img
                              src={section.image}
                              alt={section.titleSection}
                              className="img-fluid mb-3 rounded-5 w-75"
                            />
                          </Col>
                        </>
                      )
                    ) : (
                      <Col>
                        {section.content.map((paragraph, i) => (
                          <p key={i}>{paragraph}</p>
                        ))}
                      </Col>
                    )}
                  </Row>
                </div>
              ))}
          </div>
        </Container>
      </section>
    </>
  );
};

export default DynamicPage;
