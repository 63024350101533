import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/UnidadesPages/styles.css';
import { Link } from 'react-router-dom';
// import ServiceList from '../components/ServiceList.jsx';
import ServicesData from '../assets/data/Services.json';

import ServiceCard2 from '../components/extras/ServiceCard2.jsx';

const CheckUpPage = ({ pageData }) => {

  const checkUpServices = ServicesData.filter(service => 
  service && Array.isArray(service.modules) && service.modules.includes('CheckUp')
);

  
  



  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>
      <section className="unidades-section">
        <Container fluid>
                <h1 className='text-uppercase'>CLÍNICA DE {pageData.title}</h1>
                <hr />
                <Row className='p-lg-5 d-flex align-items-center'>
                    <Col className='p-lg-5' md={6}>
                    <div className="d-flex flex-column">
                            <p>Es un examen clínico diagnóstico y preventivo de el tubo digestivo, sitio de alta frecuencia de enfermedades, sangrado y cáncer en la población mexicana.</p>
                            <ul className='d-flex flex-column align-items-start unstyled'>
                                <Link className='text-primary text-decoration-none' to={'/precios'}><i className="bi bi-chevron-right mx-1"></i>Precios CheckUp</Link>
                                <Link className='text-primary text-decoration-none' to={'/precios'}><i className="bi bi-chevron-right mx-1"></i>Precios Endoscopia</Link>
                                <Link className='text-primary text-decoration-none' to={'/precios'}><i className="bi bi-chevron-right mx-1"></i>Precios Endoscopia Digestiva Alta</Link>
                            </ul>
                        {/* <p>Especialidad Médica dedicada al tratamiento de enfermedades del tubo digestivo (esófago, estómago, intestino delgado) y glándulas asociadas (hígado, páncreas) ya sea con tratamiento médico, quirúrgico convencional, cirugía laparoscópico o terapéutica endoscópica.</p> */}
                    </div>  
                    </Col>
                    <Col className='p-5 align-items-center' md={6}>
                    <div className="info-item d-flex justify-content-around">
                        <div>
                        <img className='img-fluid rounded-4' width={500} src="https://unidad-gastroenterologia.com/images/unidades/gastroenterologia.webp" alt="Imagen de unidad de gastroenterologia" />
                        </div>
                    </div>
                    </Col>
                    </Row>
                <hr />
        </Container>
        <Container className='text-start check-section'>
            <h4>Entre los Beneficios de realizarse un Check Up está la detección oportuna de diversos tipos de enfermedades, que si se les detecta a tiempo pueden ser tratadas con eficacia.</h4>
            <p>Entre las enfermedades que se pueden detectar oportunamente con el Check Up Gastrointestinal están:</p>
            <ul className='unstyled'>
                <li><i className="bi bi-chevron-right mx-1"></i>Cáncer de Esófago</li>
                <li><i className="bi bi-chevron-right mx-1"></i>Cáncer de Colon y Recto</li>
                <li><i className="bi bi-chevron-right mx-1"></i>Cáncer Gástrico</li>
                <li><i className="bi bi-chevron-right mx-1"></i>Enfermedades de la Vesícula Biliar como la Colecisitis Litiasicas, Coledocolitiasis</li>
                <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>
                    <Link to={'/reflujo'}>Enfermedad Por Reflujo Gastroesofágico (ERGE)</Link>
                </li>
                <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>
                <Link to={'/esofago-barret'}>Esofago Barret</Link>

                </li>
                <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>
                <Link to={'/polipectomia-y-mucosectomia-endoscopica'}>Poliposis Intestinal</Link>    

                </li>
                <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>
                    <Link to={'/'}>Enfermedad Diverticular de Colon</Link>
                </li>
                <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>
                    <Link to={'/hernia-hiatal'}>Hernia Hiatal</Link>
                </li>
                <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>Esofagitis y otras complicaciones del reflujo como estenosis esofágicas</li>
                <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>Gastritis</li>
                <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>Infecciones por Helicobacter pilory</li>
                <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>Enfermedad Hemorroidal</li>
                <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>Hernia Inguinal</li>
                <li><i className="bi bi-chevron-right mx-1"></i><i className="bi bi-info-circle text-info mx-1"></i>Hernias de la Pared Abdominal</li>
            </ul>
        </Container>
        <Container fluid>
        <div className='service-section p-4'>
                {/* <h5>Servicios que ofrecemos en la <br></br>Unidad de {pageData.title}:</h5>
                <p className='p-2 p-md-5'>Bienvenido a nuestra Clínica de Gastroenterología, un centro especializado en el diagnóstico, tratamiento y prevención de enfermedades del sistema digestivo. Nuestro objetivo es
                  proporcionar una <span className='text-primary'>atención médica de alta calidad</span> y respaldada por lo ultimos avances en la Gastroenterología.</p> */}
          <h4>Servicios que ofrecemos en la <br></br>Unidad de {pageData.title}:</h4>
          <p className='p-2 p-md-5'>
            Nuestra Unidad de <span className='text-primary fw-bold'>Check-Up</span> es un centro especializado en la detección temprana y
            prevención de enfermedades digestivas. Nuestro objetivo es ofrecerte una <span className='text-primary'>
            atención médica integral</span> con los más altos estándares de calidad, respaldados por los últimos avances en 
            la <span className='text-primary fw-bold'>Gastroenterología</span>.
          </p>
          <p className='p-2 text-primary fw-bold'>
            Realizarte un Check-Up te permitirá:
          </p>
          <ul className='unstyled flex-column text-start px-2'>
            <li className='d-flex align-items-center px-4'><i className="bi bi-check-circle text-success fz-1-3 mx-3"></i> Detectar enfermedades graves en etapas tempranas, como el cáncer gástrico o de colon.</li>
            <li className='d-flex align-items-center px-4'><i className="bi bi-check-circle text-success fz-1-3 mx-3"></i> Monitorear tu salud digestiva de manera personalizada.</li>
            <li className='d-flex align-items-center px-4'><i className="bi bi-check-circle text-success fz-1-3 mx-3"></i> Recibir tratamientos oportunos para evitar complicaciones.</li>
          </ul>
        </div>
        <Row className='text-start d-flex justify-content-center'>
        <Col md={10}>
            <h5 className='text-primary'>ULTRASONIDO ABDOMINAL SUPERIOR</h5>                
            <p>Revisión de Hígado, Vesícula Biliar, Vía Biliar y Páncreas.</p>

            <p>Revisión de Hígado, Vesícula Biliar, Vía Biliar, Páncreas, Bazo, Riñones.</p>
            <h5 className='text-primary'>ULTRASONIDO PÉLVICO</h5>
            <p>Revisión de Hueco Pélvicos, Próstata, Vesículas Seminales y Vejiga en el Varón, Útero, Ovarios, Salpinges y Vejiga en la Mujer.</p>

            <h5 className='text-primary'>LABORATORIOS</h5>
            <p>Perfil de Control Médico 1: Química Sanguines de 5 elementos, Biometría Hemática Completa, Exámen General de Orina.</p>

            <p>Perfil de Control Médico 2: Química Sanguines de 5 elementos, Biometría Hemática Completa, Exámen General de Orina, Coproparasitoscopico, VDRL</p>

            <h5 className='text-primary'>Protocolo de Sydney</h5>
            <p>El protocolo de sydney es un conjunto de directrices que se utiliza para la realización de biopsias en la musosa gástrica. Este protocolo es especialmente útil en la evaluación de enfermedades como la gastritis, linfoma gástico y el cáncer gástrico</p>
            <p>Tiene como objetivo clasificar las lesiones, detectar metaplasia intestinal y evaluación de la inflamación.</p>
            <h5 className='text-primary'>ESTUDIO HISTOPATOLÓGICO DE UNIÓN ESÓFAGO GÁSTRICA</h5>
            <p>Se envía a estudio histopatológico la biopsia tomada a nivel de unión esófago gástrica. En caso de haberse tomado biopsia en otro tipo de leiones estas tendrán un costo extra de 500 pesos por muestra.</p>
            <p>Todos los estudios se realizan en un día y se entregan los resultados a los 5 días en la consulta con el especialista.</p>
          </Col>
                

        </Row>
        <div className="service-section m-2">
            <h4>Paquetes que ofrecemos en la <br></br>Unidad de {pageData.title}:</h4>
            <h5 className='text-primary'>Nuestros Paquetes de Check-Up:</h5>
            <ul className='unstyled flex-column text-start p-3'>
              <li><strong>Paquete Básico:</strong> Ideal para un monitoreo inicial. Incluye consulta con gastroenterólogo, laboratorios básicos y ultrasonido abdominal.</li>
              <li><strong>Paquete Silver:</strong> Incluye todos los servicios del paquete básico, además de biopsias y endoscopía digestiva alta.</li>
              <li><strong>Paquete Gold:</strong> Un nivel superior con ultrasonido avanzado y estudios detallados de biopsias y endoscopías.</li>
              <li><strong>Paquete Platinum:</strong> Nuestro paquete más completo. Incluye colonoscopía y estudios avanzados para un diagnóstico integral.</li>
            </ul>
            <div className="cta-section">
              <h4>¡Da el primer paso hacia una mejor salud digestiva!</h4>
              <p>Elige el paquete de Check-Up que más se adapte a tus necesidades y asegura un diagnóstico temprano y preciso con nuestros especialistas.</p>
            </div>
            <Row>
              {checkUpServices.map((service) => (
                <Col key={service.id} md={3} className="d-flex">
                  <ServiceCard2 service={service} />
                </Col>
              ))}
            </Row>
            <p className='text-primary text-center mt-4'>
              Todos los paquetes están diseñados para ofrecerte resultados rápidos y confiables, entregados por nuestros 
              especialistas altamente capacitados.
            </p>
            
          </div>
      </Container>


      </section>

    </>
  );
};

export default CheckUpPage;
