import React from 'react';
import { Helmet } from 'react-helmet-async';
import DoctoresSection from '../components/DoctoresSection.jsx';

const DoctoresPage = ({ pageData }) => {


  // const { title, description, keywords, canonical } = pageData;

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>
      <DoctoresSection />
    </>
  );
};

export default DoctoresPage;
