// DynamicPagesDataHelper.js
import DynamicPagesData from '../assets/data/DynamicPagesData.jsx';
import "../assets/css/DynamicPages/stylesCard.css";

// Función para agrupar los datos según la Rama Principal
export const groupDynamicDataByModule = () => {
  const groupedData = {};

  // Recorremos los módulos
  Object.entries(DynamicPagesData).forEach(([moduleKey, moduleData]) => {
    // Inicializamos el array para cada módulo si no existe
    if (!groupedData[moduleKey]) {
      groupedData[moduleKey] = [];
    }

    // Agregamos las páginas al módulo correspondiente
    Object.entries(moduleData).forEach(([pageKey, pageData]) => {
      groupedData[moduleKey].push({
        title: pageData.title,
        image: pageData.image,
        link: pageData.link,
      });
    });
  });

  return groupedData;
};

