import React, { useState, Suspense, lazy } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import '../assets/css/UnidadesPages/styles.css';
import ServiceList from '../components/ServiceList.jsx';

const CarouselSeguros = lazy(() => import('../components/Carousel-Seguros.jsx'));

const PreciosPage = ({ title, description, keywords, canonical }) => {
  const [selectedService, setSelectedService] = useState(null);

  const handleServiceSelect = (service) => {
    setSelectedService(service);
  };

  return (
    <>
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <link rel='canonical' href={canonical} />
    </Helmet>
      <section className="unidades-section">
        <Container fluid>
          <div className="my-5">
            <div className="my-5">
              <h1>Precios de Servicios Médicos</h1>
              <p>Con más de 15 años de experiencia en el área de la Medicina, conociendo a los pacientes, a las enfermedades y a los profesionales de la salud, nos dedicamos a brindar la mejor atención a nuestros pacientes.</p>
              <hr />
              <div className='doctor-section-content'>

                <div>
                  <button className={`button-menu-doctor ${selectedService === 'Consulta' ? 'active' : ''}`} onClick={() => handleServiceSelect('Consulta')}>Consulta</button>
                  <button className={`button-menu-doctor ${selectedService === 'Estudios' ? 'active' : ''}`} onClick={() => handleServiceSelect('Estudios')}>Estudios</button>
                  <button className={`button-menu-doctor ${selectedService === null ? 'active' : ''}`} onClick={() => handleServiceSelect(null)}>Mostrar Todos</button>
                </div>
                <h3 className='mt-4 fz-3'>Precios de Coloproctología</h3>
                <ServiceList serviceToShow={selectedService} moduleToShow="Coloproctología" />
                <h3 className='mt-4 fz-3'>Precios de Gastroenterología</h3>
                <ServiceList serviceToShow={selectedService} moduleToShow="Gastroenterología" />
                <h3 className='mt-4 fz-3'>Precios de CheckUp</h3>
                <ServiceList serviceToShow={selectedService} moduleToShow="CheckUp" />
                <h3 className='mt-4 fz-3'>Precios de Ultrasonido</h3>
                <ServiceList serviceToShow={selectedService} moduleToShow="Ultrasonido" />
              </div>
            </div>
          </div>
          <Suspense fallback={<div className="loader">Cargando unidades médicas...</div>}>
            <CarouselSeguros />
          </Suspense>
        </Container>
      </section>
    </>
  );
};



export default PreciosPage;
