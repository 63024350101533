import React from 'react';
import PropTypes from 'prop-types';

const ServiceCard2 = ({ service }) => {
  // Validaciones para evitar errores
  const name = service.name || 'Servicio no disponible';
  const photoUrl = service.photoUrl || 'https://via.placeholder.com/150';
  const price = service.priceVariations?.conventional?.price || 0;
  const needIva = service.priceVariations?.conventional?.NeedIva || '';
  const included = Array.isArray(service.included) ? service.included : [];
  
  const whatsappLink = `https://api.whatsapp.com/send?phone=5215534816054&text=Hola!%2C%20Me%20interesa%20el%20servicio%20de%20Check%20Up%20${encodeURIComponent(
    name
  )}%20Podrías%20darme%20más%20información%3F`;

  return (
    <div className="service-details my-4">
      <h4 className="text-uppercase text-primary">{name}</h4>
      <img src={photoUrl} className="card-img-top img-fluid" alt={`Imagen de ${name}`} />
      <p className="text-price">Costo:</p>
      <p className='text-bold'>${price} MXN {needIva}</p>
      <h5 className="text-primary">Incluye:</h5>
      <ul>
        {included.map((item, index) => (
          <li key={index}><i className="bi bi-check-lg"></i>{item}</li>
        ))}
      </ul>
      <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="btn-contacto btn-general">
        ¡Pedir Ahora!
      </a>
    </div>
  );
};

ServiceCard2.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    photoUrl: PropTypes.string,
    priceVariations: PropTypes.shape({
      conventional: PropTypes.shape({
        price: PropTypes.number,
        NeedIva: PropTypes.string,
      }),
    }),
    included: PropTypes.arrayOf(PropTypes.string),
  }),
};

ServiceCard2.defaultProps = {
  service: {
    name: 'Servicio no disponible',
    photoUrl: 'https://via.placeholder.com/150',
    priceVariations: {
      conventional: {
        price: 0,
        NeedIva: '',
      },
    },
    included: [],
  },
};

export default ServiceCard2;
