import React from 'react';
import { Row, Col } from 'react-bootstrap';

const endoscopiaData = [
  {
    title: 'Stretta o Gastroplastia Esofago Gastrica Con Radiofrecuencia',
    image: 'https://unidad-gastroenterologia.com/images/stretta-o-gastroplastia-esofago-gastrica.png',
    link: '/stretta-gastroplastia-radiofrecuencia',
  },
  {
    title: 'Tratamiento Sangrado de Ulcera Gastrica',
    image: 'https://unidad-gastroenterologia.com/images/tratamiento-sangrado-de-ulcera-gastrica.jpg',
    link: '/ulcera',
  },
  {
    title: 'Dilatación Esofagica',
    image: 'https://unidad-gastroenterologia.com/images/dilatacion-esofagica.jpg',
    link: '/dilatacion-endoscopica',
  },
  {
    title: 'Ligadura de Varices Esofagicas',
    image: 'https://unidad-gastroenterologia.com/images/ligadura-de-varices-esofagicas.jpg',
    link: '/ligadura-de-varices-esofagicas',
  },
  {
    title: 'Balon Intragastrico Ajustable Spatz 3 de 1 año',
    image: 'https://unidad-gastroenterologia.com/images/balon-intragastrico-ajustable-spatz.jpeg',
    link: 'http://www.balongastricocdmx.com/',
    external: true,
  },
  {
    title: 'Polipéctomias y Mucosectomias Endoscopicas',
    image: 'https://unidad-gastroenterologia.com/images/polipectomia-con-asa.jpg',
    link: '/polipectomia-y-mucosectomia-endoscopica',
  },
  {
    title: 'Manga Gástrica Endoscópica',
    image: 'https://unidad-gastroenterologia.com/images/manga-gastrica-endoscopica.jpg',
    link: 'http://mangagastricaendoscopica.mx/',
    external: true,
  },
  {
    title: 'Tratamiento Endoscópico con Argon Plasma',
    image: 'https://unidad-gastroenterologia.com/images/tratamiento-endoscopico-con-argon-plasma.jpg',
    link: '/tratamiento-endoscopico-argon-plasma',
  },
  {
    title: 'Ablación de Esófago de Barret con Radiofrecuencia',
    image: 'https://unidad-gastroenterologia.com/images/ablacion-de-esofago-de-barret-con-radiofrecuencia.jpg',
    link: '/ablacion-esofago-barrett',
  },
];
// Componente para renderizar las tarjetas
const EndoscopiaCards = () => {
  return ( 

 
    <Row className='mt-5'>
      {endoscopiaData.map((endoscopia, index) => (
        <Col key={index} md={4} className='mt-4'> {endoscopia.external ? ( 
        <a href={endoscopia.link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`${endoscopia.title} (abre en una nueva pestaña)`}
        className="Cards-Endoscopia">
            <img src={endoscopia.image} alt={`Icono de ${endoscopia.title}`} className="img-fluid" />
            <p>{endoscopia.title}</p>
        </a>

         ) :  (
            <a href={endoscopia.link} className='Cards-Endoscopia'>
                <img src={endoscopia.image} alt={`Icono de ${endoscopia.title}`} className="img-fluid" />
                <p>{endoscopia.title}</p>
            </a>)}
        </Col>
      ))}
    </Row>
  );
};

export default EndoscopiaCards;
