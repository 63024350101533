import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import '../assets/css/DynamicPages/styles.css';

const DynamicPricePage = ({ pageData }) => {
  if (!pageData) {
    return <div>Contenido no disponible</div>;
  }

  // Asegúrate de que pageData.link o pageData.canonical esté definido
  const pageUrl = pageData.canonical || pageData.link || '/';

  return (
    <>
      {/* Configuración del SEO con Helmet */}
      <Helmet>
        <title>{pageData.name}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageUrl} />
      </Helmet>

      {/* Contenido dinámico */}
      <section className={`dynamic-price-page-section ${pageData.className || ''}`}>
        <Container>
          <div className="my-5">
            {/* Imagen principal */}
            <Row>
              <Col>
                <img src={pageData.photoUrl} alt={pageData.name} className="img-fluid mb-4 rounded-5" />
              </Col>
            </Row>

            <h1 className="service-title">{pageData.name}</h1>
            <p>{pageData.description}</p>

            {/* Requisitos y preparación */}
            {pageData.requirements && (
              <>
                <h5>Preparación</h5>
                <p>{pageData.requirements}</p>
              </>
            )}

            {/* Mostrar las variaciones de precio */}
            <div className="price-variations">
              {Object.entries(pageData.priceVariations).map(([variationKey, variation]) => (
                <Card key={variationKey} className="mb-3">
                  <Card.Body>
                    <h5>{variation.name}</h5>
                    <p className="price">
                      {variation.price} {variation.NeedIva && <span>+ IVA</span>}
                    </p>
                    <ul>
                      {pageData.included && pageData.included.length > 0 && (
                        <>
                          <h6>Incluye:</h6>
                          {pageData.included.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </>
                      )}
                      {pageData.excluded && pageData.excluded.length > 0 && (
                        <>
                          <h6>No incluye:</h6>
                          {pageData.excluded.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </>
                      )}
                    </ul>
                  </Card.Body>
                </Card>
              ))}
            </div>

            {/* Información adicional sobre el servicio */}
            {pageData.modules && pageData.modules.includes("Gastroenterología") && (
              <>
                <h5>¿Dónde y cómo se realiza este servicio?</h5>
                <p>El servicio se realiza en nuestra unidad especializada de <strong>Medicci: Unidad de Gastroenterología y Endoscopia Digestiva</strong>, utilizando tecnología de última generación y bajo sedación anestésica.</p>
              </>
            )}

            {/* Botón de contacto */}
            <Button
              variant="primary"
              href={`https://api.whatsapp.com/send?phone=5215534816054&text=Hola!%2C%20Me%20interesa%20${encodeURIComponent(pageData.name)}%2C%20podrias%20darme%20más%20información%3F`}
            >
              Más Información
            </Button>
          </div>
        </Container>
      </section>
    </>
  );
};

export default DynamicPricePage;
