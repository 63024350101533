import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import { groupDynamicDataByModule } from '../components/DynamicCards.jsx';

const EnfermedadesCards = ({ pageData }) => {
  // Obtenemos los datos agrupados
  const groupedData = groupDynamicDataByModule();

  // Definimos el orden y los módulos que queremos mostrar
  const modulesToDisplay = [
    { key: 'EnfermedadesDeEsofago', title: 'ENFERMEDADES DE ESÓFAGO', id: 'enfermedades-de-esofago' },
    { key: 'Gastroenterologia', title: 'ENFERMEDADES DE ESTÓMAGO', id: 'enfermedades-de-estomago' },
    { key: 'EnfermedadesHigadoYViasBiliares', title: 'ENFERMEDADES DE HÍGADO Y VÍAS BILIARES', id: 'enfermedades-higado-vias-biliares' },
    { key: 'EnfermedadesDePancreas', title: 'ENFERMEDADES DE PÁNCREAS', id: 'enfermedades-de-pancreas' },
    { key: 'EnfermedadesDeColon', title: 'ENFERMEDADES DE COLON', id: 'enfermedades-de-colon' },
    // { key: 'Coloproctologia', title: 'Coloproctología', id: 'coloproctologia' },
    // { key: 'Endoscopia', title: 'Endoscopia Digestiva', id: 'endoscopia' },
    { key: 'EnfermedadesAnoRectales', title: 'Enfermedades Ano-Rectales', id: 'enfermedades-ano-rectales' },
  ];

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1); // Obtiene el hash sin el #
      const element = document.getElementById(hash); // Encuentra el elemento por su ID
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    handleHashChange(); // Ejecuta al cargar la página
    window.addEventListener('hashchange', handleHashChange); // Escucha cambios en el hash

    return () => {
      window.removeEventListener('hashchange', handleHashChange); // Limpia el evento al desmontar
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageData.title}</title>
        <meta name="description" content={pageData.description} />
        <meta name="keywords" content={pageData.keywords} />
        <link rel="canonical" href={pageData.canonical} />
      </Helmet>

      <section>
        <Container>
          {modulesToDisplay.map(({ key, title, id }) => {
            const moduleCards = groupedData[key] || [];
            if (moduleCards.length === 0) return null;

            return (
              <div key={key} id={id} className="mb-5">
                <h2 className="text-center text-primary">{title}</h2>
                <Row className="mt-3">
                  {moduleCards.map((item, index) => (
                    <Col key={index} md={4} className="mb-4">
                      <div className="card card-publicaciones">
                        <img
                          src={item.image}
                          alt={`Icono de ${item.title}`}
                          className="card-img-top"
                        />
                        <div className="card-body">
                          <h5 className="card-title">{item.title}</h5>
                          <p className="card-text">
                            {item.description && item.description.length > 100
                              ? `${item.description.substring(0, 100)}...`
                              : item.description}
                          </p>
                          <a href={item.link} className="btn-publicaciones">
                            Leer más
                          </a>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            );
          })}
        </Container>
      </section>
    </>
  );
};

export default EnfermedadesCards;
